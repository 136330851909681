import React, { FC, useState } from "react";
import { Api } from "models";
import { CardHeader, Card, Table, Pagination, PaginationItem, PaginationLink, Input, InputGroup, InputGroupAddon, InputGroupText, Button } from "reactstrap";
import { getGRDate, getGRCurrency } from "utilities";
import "./Statement.scss";
import { ReactComponent as DocumentIcon } from "assets/material/document.svg";
import { useTranslation } from "react-i18next";

interface StatementProps {
   title: string;
   movements: Api.Hydrometer.Movement[];
   chunckSize: number;
   onPdf: (url: string, fileName: string) => void;
}

const Statement: FC<StatementProps> = (props) => {
   const [t] = useTranslation("hydrometer");
   const chunckQ = Math.ceil(props.movements.length / props.chunckSize);
   const remainder = props.movements.length % props.chunckSize;
   const [page, setPage] = useState(chunckQ);

   const start = (page - 2) * props.chunckSize + (remainder || props.chunckSize);
   const c = props.movements.slice(Math.max(start, 0), Math.min(start + props.chunckSize, props.movements.length));
   if (start > 0)
      c.unshift({
         code: "",
         issueDate: props.movements[start - 1].issueDate,
         balance: props.movements[start - 1].balance,
         credit: 0,
         debit: 0,
         name: t("hydrometer:reminder"),
         shortcut: "",
         findoc: 0,
         printable: false
      });

   return (
      <Card className="statement shadow border-0 mt-md-2 mb-4">
         <CardHeader className="font-weight-bold lead">{props.title}</CardHeader>
         <Table responsive hover striped className="d-none d-md-table">
            <thead>
               <tr>
                  <th>{t("hydrometer:date")}</th>
                  <th>{t("hydrometer:document")}</th>
                  <th>{t("hydrometer:description")}</th>
                  <th>{t("hydrometer:debit")}</th>
                  <th>{t("hydrometer:credit")}</th>
                  <th>{t("hydrometer:balance")}</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               {c.map((m, i) =>
                  m.name === t("hydrometer:reminder") ? (
                     <tr key={`chunk_${page}_movements_${i}`}>
                        <td></td>
                        <td></td>
                        <td className="text-info">{m.name}</td>
                        <td></td>
                        <td></td>
                        <td className="text-info">{getGRCurrency(m.balance)}</td>
                        <td></td>
                     </tr>
                  ) : (
                     <tr key={`chunk_${page}_movements_${i}`}>
                        <td>{getGRDate(new Date(m.issueDate), "L")}</td>
                        <td>{m.code}</td>
                        <td>{m.name}</td>
                        <td>{m.debit ? getGRCurrency(m.debit) : null}</td>
                        <td>{m.credit ? getGRCurrency(m.credit) : null}</td>
                        <td>{getGRCurrency(m.balance)}</td>
                        <td>
                           {m.printable && (
                              <>
                                 <Button
                                    color="info"
                                    onClick={() => props.onPdf(`${window.location.origin}/Print/${m.findoc}/`, m.code)}
                                    outline
                                    size="sm"
                                    className=" d-none d-lg-inline-block"
                                 >
                                    {t("hydrometer:show")}
                                 </Button>
                                 <DocumentIcon
                                    onClick={() => props.onPdf(`${window.location.origin}/Print/${m.findoc}/`, m.code)}
                                    fill="#6ecde3"
                                    className="d-lg-none"
                                    style={{ cursor: "pointer" }}
                                 />
                              </>
                           )}
                        </td>
                     </tr>
                  )
               )}
            </tbody>
         </Table>

         <Table responsive hover striped className="d-md-none">
            <thead>
               <tr>
                  <th>{t("hydrometer:dat")}</th>
                  <th>{t("hydrometer:desc")}</th>
                  <th className="text-right">{t("hydrometer:d/c")}</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               {c.map((m, i) =>
                  m.name === t("hydrometer:reminder") ? (
                     <tr key="transfered_balance">
                        <td colSpan={2} className="text-info">
                           {m.name}
                        </td>
                        <td className="text-right text-info">{getGRCurrency(m.balance)}</td>
                        <td></td>
                     </tr>
                  ) : (
                     <tr key={`chunk_${page}_movements_${i}`}>
                        <td>{getGRDate(m.issueDate, "L")}</td>
                        <td>{m.shortcut}</td>
                        <td className="text-right">{getGRCurrency(m.debit - m.credit)}</td>
                        <td>{m.printable && <DocumentIcon onClick={() => props.onPdf(`${window.location.origin}/Print/${m.findoc}/`, m.code)} fill="#6ecde3" />}</td>
                     </tr>
                  )
               )}
               <tr>
                  <td colSpan={2} className="text-info">
                     {t("hydrometer:balance")}
                  </td>
                  <td className="text-info text-right">{getGRCurrency(c.reverse()[0].balance)}</td>
                  <td></td>
               </tr>
            </tbody>
         </Table>

         <Pagination className="align-self-center">
            <PaginationItem disabled={page === 1}>
               <PaginationLink first onClick={() => setPage(1)} />
            </PaginationItem>
            <PaginationItem disabled={page === 1}>
               <PaginationLink previous onClick={() => setPage(Math.max(1, page - 1))} />
            </PaginationItem>

            <InputGroup>
               <Input
                  type="number"
                  min={1}
                  max={chunckQ}
                  value={page}
                  onChange={(ev) => setPage(Math.max(parseInt(ev.target.min), Math.min(parseInt(ev.target.value), parseInt(ev.target.max))))}
                  style={{ width: 80 }}
               />
               <InputGroupAddon addonType="append" className="d-none d-sm-inline-block">
                  <InputGroupText> {" / " + chunckQ}</InputGroupText>
               </InputGroupAddon>
            </InputGroup>

            <PaginationItem disabled={page === chunckQ}>
               <PaginationLink next onClick={() => setPage(Math.min(page + 1, chunckQ))} />
            </PaginationItem>
            <PaginationItem disabled={page === chunckQ}>
               <PaginationLink last onClick={() => setPage(chunckQ)} />
            </PaginationItem>
         </Pagination>
      </Card>
   );
};

export default Statement;
