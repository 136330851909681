import React, { Component, Fragment } from "react";
import api, { ServerError } from "api";
import { BillModel } from "models";
import { AxiosResponse } from "axios";
import { Table, Card, CardBody, CardTitle, Col, Row, InputGroup, InputGroupAddon, Input, Form } from "reactstrap";
import Warning from "../../Warning";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getGRCurrency } from "utilities";
import { RouteComponentProps } from "react-router";
import withError from "hoc/withError/withError";
import Bill from "components/Pay/Bill/Bill";
import { connect } from "react-redux";
import { ReduxState } from "store";
import PayBtn from "containers/Pay/PayBtn";
import { WithTranslation, withTranslation } from "react-i18next";

interface BillsProps extends RouteComponentProps {
   eCodes: string[];
   auth: boolean;
}

interface BillsState {
   bills: BillModel[];
   mobile: string;
   loading: boolean;
   error?: Error;
   creating: boolean;
}

class Bills extends Component<BillsProps & { mobile: string } & WithTranslation, BillsState> {
   constructor(props: BillsProps & { mobile: string } & WithTranslation) {
      super(props);
      this.state = {
         bills: [],
         mobile: props.mobile || "",
         loading: true,
         creating: false
      };
   }

   componentDidMount = () => {
      api.post<BillsProps, AxiosResponse<BillModel[]>>("/Bills/OfECodes", { data: this.props.eCodes })
         .then((res) => {
            this.setState({
               bills: res.data,
               loading: false
            });
         })
         .catch((e) => {
            if (e.response) {
               this.setState({ error: new ServerError(e.response.data?.detail) });
            } else this.setState({ error: new Error(e) });
         });
   };

   pay = () => {
      this.setState({ creating: true });
      let createReq: { bills: BillModel[] } = { bills: JSON.parse(JSON.stringify(this.state.bills)) };
      createReq.bills = createReq.bills.filter((b) => b.payAmount && b.payAmount > 0);
      if (createReq.bills.length) {
         api.post("/Pay/Create", createReq)
            .then((createRes: AxiosResponse<any>) => {
               if (createRes.data) {
                  this.props.history.push("/Pay", {
                     amount: (createRes.data.amount * 100).toFixed(0),
                     reference: `${createRes.data.id};${createRes.data.guid};${this.state.mobile}`
                  });
               }
               // return createRes;
            })
            .catch((e) => {
               if (e.response) this.setState({ error: new ServerError(e.response.data?.detail) });
               else this.setState({ error: new Error(e) });
            })
            .finally(() => this.setState({ creating: false }));
      } else this.setState({ creating: false });
   };

   getBillSum = (billTypeFilter?: (billType: number) => boolean) => {
      if (billTypeFilter) {
         return this.state.bills.filter((bill) => billTypeFilter(bill.billType)).reduce((sum, bill) => sum + (bill.payAmount || 0), 0);
      } else {
         return this.state.bills.reduce((sum, bill) => sum + (bill.payAmount || 0), 0);
      }
   };

   render() {
      if (this.state.error) throw this.state.error;
      else if (this.state.loading) return this.loadingView();
      else if (this.state.bills.length > 0)
         return (
            <Fragment>
               {this.billsView()}
               {this.sumNPayCard()}
            </Fragment>
         );
      else throw new Warning(this.props.t("no_bills_warning"));
   }

   loadingView = () => (
      <SkeletonTheme color="#fff" highlightColor="#fafafa">
         <Row>
            {this.props.eCodes.map((_, i) => (
               <Col xs={12} md={6} key={"skeleton_" + i}>
                  <Skeleton duration={2} className="mb-4 px-0 mx-0 shadow-sm border-0" height={235} />
               </Col>
            )) || null}
         </Row>
         <Row>
            <Col xs={12}>
               <Skeleton duration={2} className="px-0 mx-0 shadow-sm border-0" height={287} />
            </Col>
         </Row>
      </SkeletonTheme>
   );

   billsView = () => (
      <Row>
         {this.state.bills
            .filter((bill) => bill.error)
            .map((bill, i) => (
               <Col xs={12} key={"error_bill_" + i}>
                  <Bill
                     className="shadow-sm border-0"
                     {...(bill as BillModel)}
                     onAmountChange={(amount) => {
                        this.setState((prevState) => {
                           try {
                              const bills: BillModel[] = JSON.parse(JSON.stringify(prevState.bills));
                              bills[i].payAmount = amount;
                              return { bills };
                           } catch (err) {
                              return { bills: prevState.bills };
                           }
                        });
                     }}
                  />
               </Col>
            ))}
         {this.state.bills
            .filter((bill) => !bill.error)
            .map((bill, i) => (
               <Col xs={12} md={6} key={"bill_" + i}>
                  <Bill
                     className="shadow-sm border-0 mb-4"
                     {...(bill as BillModel)}
                     onAmountChange={(amount) => {
                        this.setState((prevState) => {
                           try {
                              const bills: BillModel[] = JSON.parse(JSON.stringify(prevState.bills));
                              bills[i].payAmount = amount;
                              return { bills };
                           } catch (err) {
                              return { bills: prevState.bills };
                           }
                        });
                     }}
                  />
               </Col>
            ))}
      </Row>
   );

   getSums = () => {
      const installments = this.getBillSum((d) => d === 1);
      const bills = this.getBillSum((d) => d === 0);
      if (installments > 0 && bills > 0)
         return {
            [this.props.t("bill_sum")]: getGRCurrency(bills),
            [this.props.t("installment_sum")]: getGRCurrency(installments),
            [this.props.t("sum")]: getGRCurrency(this.getBillSum())
         };
      else
         return {
            [this.props.t("pay_sum")]: getGRCurrency(this.getBillSum())
         };
   };

   sumsTable = () => {
      const sums: any = this.getSums();
      return (
         <>
            <div className="d-sm-none">
               <hr className="my-3" />
               {Object.keys(sums).map((k, i) => (
                  <div key={"sums_" + i} className="mb-3">
                     <p className="text-right font-weight-bold mt-2 mb-0">{k}</p>
                     <p className="text-right mt-0 mb-0">{sums[k]}</p>
                  </div>
               ))}
            </div>
            <div className="d-none d-sm-block">
               <Table>
                  <tbody>
                     {Object.keys(sums).map((k, i) => (
                        <tr key={"sums_" + i}>
                           <th scope="row">{k}</th>
                           <td align="right"> {sums[k]}</td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </>
      );
   };

   sumNPayCard = () => (
      <Card className="mt-4 shadow-sm border-0">
         <CardBody>
            <CardTitle className="lead">
               <strong>{this.props.t("payment_confirmation")}</strong>
            </CardTitle>
            {this.sumsTable()}
            {this.props.auth && this.props.mobile ? (
               <PayBtn phone={this.props.mobile} className="float-right mt-1" color="primary" bills={this.state.bills}>
                  {this.props.t("pay")}
               </PayBtn>
            ) : (
               <Form
                  onSubmit={(ev) => {
                     ev.preventDefault();
                  }}
               >
                  <Row className="d-flex justify-content-end">
                     <Col xs={12} md={6} className="mt-1">
                        <InputGroup>
                           <Input
                              pattern="\d+"
                              title={this.props.t("mobile_msg")}
                              required
                              type="text"
                              placeholder={this.props.t("mobile_placeholder")}
                              value={this.state.mobile}
                              onChange={(ev) => this.setState({ mobile: ev.target.value })}
                           />
                           <InputGroupAddon addonType="append">
                              <PayBtn phone={this.state.mobile} color="primary" type="submit" bills={this.state.bills}>
                                 {this.props.t("pay")}
                              </PayBtn>
                           </InputGroupAddon>
                        </InputGroup>
                     </Col>
                  </Row>
               </Form>
            )}
         </CardBody>
      </Card>
   );
}

export default connect<{ mobile: string; auth: boolean }, unknown, unknown, ReduxState>((state) => ({
   mobile: (state.userInfo?.phoneNumberDialCode || "") + (state.userInfo?.phoneNumber || ""),
   auth: state.isAuthenticated
}))(withTranslation("bills")(withError(Bills)));
