import api from "api";
import { UserModel } from "models";
import moment, { isMoment } from "moment";
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
   Cell,
   Column,
   SortingRule,
   useAsyncDebounce,
   useBlockLayout,
   useGlobalFilter,
   UseGlobalFiltersInstanceProps,
   UseGlobalFiltersState,
   usePagination,
   useSortBy,
   useTable
} from "react-table";
import { useSticky } from "react-table-sticky";
import {
   Button,
   ButtonDropdown,
   Card,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   Input,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
   Modal,
   ModalBody,
   ModalFooter,
   Pagination,
   PaginationItem,
   PaginationLink,
   Table
} from "reactstrap";
import "./Admin.scss";
import { ReactComponent as UpArrow } from "assets/material/arrow_upward.svg";
import { ReactComponent as DownArrow } from "assets/material/arrow_downward.svg";
import { useReactToPrint } from "react-to-print";
import { forwardRef } from "react";

const Printable = forwardRef<HTMLTableElement, { data: UserModel[]; globalFilter: string; sort: SortingRule<UserModel>[] }>(({ data, globalFilter, sort }, ref) => {
   console.log("Render printable");
   const [t] = useTranslation();
   const { rows, headerGroups, getTableBodyProps, getTableProps, prepareRow, setGlobalFilter, setSortBy } = useTable(
      {
         data,
         columns: useMemo<Column<UserModel>[]>(
            () => [
               { accessor: "afm", Header: t("admin:afm").toString(), width: 120 },
               {
                  accessor: ({ name }) => {
                     const names = name.split("|");
                     return `${names[0]} ${names[1]}`;
                  },
                  id: "name",
                  Header: t("admin:name").toString(),
                  width: 250
               },
               {
                  id: "created",
                  accessor: ({ created }) => (moment(created).isValid() ? moment(created) : null),
                  Header: t("admin:created").toString(),
                  Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm") : ""),
                  width: 170,
                  sortType: (row1, row2) => moment(new Date(row1.original.created)).diff(new Date(row2.original.created)),
                  sortDescFirst: true
               },
               {
                  Header: t("admin:phone_number_info").toString(),
                  columns: [
                     {
                        accessor: ({ phoneNumberDialCode, phoneNumber }) =>
                           phoneNumberDialCode || phoneNumber ? `${phoneNumberDialCode ? "+" + phoneNumberDialCode : ""} ${phoneNumber || ""}` : "",
                        id: "phoneNumber",
                        Header: t("admin:phone_number").toString(),
                        width: 160,
                        disableSortBy: true
                     },
                     { accessor: "phoneNumberToken", Header: t("admin:phone_number_token").toString(), width: 90, disableSortBy: true },
                     {
                        id: "phoneNumberTokenCreated",
                        accessor: ({ phoneNumberTokenCreated }) => (moment(phoneNumberTokenCreated).isValid() ? moment(phoneNumberTokenCreated) : null),
                        Header: t("admin:phone_number_token_created").toString(),
                        Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm:ss") : ""),
                        width: 190,
                        sortType: (row1, row2) => moment(new Date(row1.original.phoneNumberTokenCreated)).diff(new Date(row2.original.phoneNumberTokenCreated)),
                        sortDescFirst: true
                     },
                     {
                        accessor: "phoneNumberConfirmTries",
                        Header: t("admin:phone_number_confirm_tries").toString(),
                        width: 100,
                        sortType: "number",
                        Cell: ({ value }) => <span className={value > 6 ? "text-danger" : ""}>{value}</span>
                     },
                     {
                        accessor: "phoneNumberConfirmed",
                        Header: t("admin:phone_number_confirmed").toString(),
                        Cell: ({ value }) => t(value ? "admin:yes" : "admin:no").toString(),
                        width: 100,
                        sortType: (row1, row2) => (row1.original.phoneNumberConfirmed ? 1 : 0) - (row2.original.phoneNumberConfirmed ? 1 : 0)
                     }
                  ]
               },
               {
                  Header: t("admin:email_info").toString(),
                  columns: [
                     {
                        accessor: "email",
                        Header: t("admin:email").toString(),
                        width: 300,
                        disableSortBy: true
                     },
                     { accessor: "emailToken", Header: t("admin:email_token").toString(), width: 90, disableSortBy: true },
                     {
                        id: "emailTokenCreated",
                        accessor: ({ emailTokenCreated }) => (moment(emailTokenCreated).isValid() ? moment(emailTokenCreated) : null),
                        Header: t("admin:email_token_created").toString(),
                        Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm:ss") : ""),
                        width: 190,
                        sortType: (row1, row2) => moment(new Date(row1.original.emailTokenCreated)).diff(new Date(row2.original.emailTokenCreated)),
                        sortDescFirst: true
                     },
                     {
                        accessor: "emailConfirmTries",
                        Header: t("admin:email_confirm_tries").toString(),
                        width: 100,
                        sortType: "number",
                        Cell: ({ value }) => <span className={value > 6 ? "text-danger" : ""}>{value}</span>
                     },
                     {
                        accessor: "emailConfirmed",
                        Header: t("admin:email_confirmed").toString(),
                        Cell: ({ value }) => t(value ? "admin:yes" : "admin:no").toString(),
                        width: 100,
                        sortType: (row1, row2) => (row1.original.emailConfirmed ? 1 : 0) - (row2.original.emailConfirmed ? 1 : 0)
                     }
                  ]
               }
            ],
            []
         )
      },
      useGlobalFilter,
      useSortBy
   );

   useEffect(() => {
      setSortBy(sort);
   }, [sort]);

   useEffect(() => {
      setGlobalFilter(globalFilter);
   }, [globalFilter]);

   return (
      <table ref={ref} cellPadding="5px" cellSpacing="0" {...getTableProps()}>
         <thead>
            {headerGroups.map((hg) => (
               <tr {...hg.getHeaderGroupProps()}>
                  {hg.headers.map((column) => (
                     <th {...column.getHeaderProps({ style: { border: "1px solid grey" } })}>{column.render("Header")}</th>
                  ))}
               </tr>
            ))}
         </thead>
         <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
               prepareRow(row);
               return (
                  <tr {...row.getRowProps({ style: { backgroundColor: i % 2 === 0 ? "rgb(220, 220, 220)" : "white" } })}>
                     {row.cells.map((cell) => (
                        <td {...cell.getCellProps({ style: { border: "1px solid grey" } })}>{cell.render("Cell")}</td>
                     ))}
                  </tr>
               );
            })}
         </tbody>
      </table>
   );
});

const GlobalFilter: React.FC<Partial<UseGlobalFiltersInstanceProps<any>> & UseGlobalFiltersState<any>> = ({ globalFilter, setGlobalFilter }) => {
   const [value, setValue] = React.useState(globalFilter);
   const onChange = useAsyncDebounce((value) => {
      if (setGlobalFilter) setGlobalFilter(value || undefined);
   }, 200);
   const [t] = useTranslation("admin");

   return (
      <Input
         type="search"
         value={value || ""}
         onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
         }}
         placeholder={t("admin:search")}
      />
   );
};

const ActionsCell: React.FC<{
   value: UserModel;
   onAction: (id: string, action: string) => void;
   cell: Cell;
}> = ({ value, onAction, cell }) => {
   const [open, setOpen] = useState(false);
   const [t] = useTranslation(["admin", "errors"]);
   const handleClick: (id: string, action: string) => MouseEventHandler<any> = (id, action) => (ev) => {
      onAction(id, action);
      setOpen(false);
   };
   return (
      <td {...cell.getCellProps({ style: { zIndex: open ? 4 : 3 } })}>
         <ButtonDropdown isOpen={open} toggle={() => setOpen((open) => !open)}>
            <DropdownToggle color={value.emailConfirmed && value.phoneNumberConfirmed ? "success" : "danger"} caret>
               {t("admin:open").toString()}
            </DropdownToggle>
            <DropdownMenu>
               {!value.emailConfirmed && <DropdownItem onClick={handleClick(value.id, "ConfirmUserEmail")}>{t("admin:confirm_email").toString()}</DropdownItem>}
               {!value.phoneNumberConfirmed && (
                  <DropdownItem onClick={handleClick(value.id, "ConfirmUserPhoneNumber")}>{t("admin:confirm_phone_number").toString()}</DropdownItem>
               )}
               {(!value.emailConfirmed || !value.phoneNumberConfirmed) && (
                  <DropdownItem onClick={handleClick(value.id, "ActivateUser")}>{t("admin:activate_user").toString()}</DropdownItem>
               )}
               <DropdownItem className="text-danger" onClick={handleClick(value.id, "DeleteUser")}>
                  {t("admin:delete").toString()}
               </DropdownItem>
            </DropdownMenu>
         </ButtonDropdown>
      </td>
   );
};

const AdminTable: React.FC<{ data: UserModel[]; getAction: (id: string, action: string) => () => void; loading: boolean }> = ({ data, getAction, loading }) => {
   const [userAction, setUserAction] = useState<{ invoke: () => void; modalOpen: boolean } | null>(null);
   const handleAction = useCallback((id: string, action: string) => {
      setUserAction({
         invoke: getAction(id, action),
         modalOpen: true
      });
   }, []);
   const handleFinishAction = useCallback(() => setUserAction({ invoke: () => {}, modalOpen: false }), []);
   const [showPrintable, setShowPrintable] = useState(false);
   const [printable, setPrintable] = useState<HTMLTableElement | null>(null);
   const handlePrint = useReactToPrint({
      content: () => printable,
      copyStyles: false,
      onAfterPrint: () => {
         setPrintable(null);
         setShowPrintable(false);
      }
   });

   const [t] = useTranslation(["admin", "errors"]);
   const {
      page,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      state,
      pageCount,
      canNextPage,
      nextPage,
      previousPage,
      canPreviousPage,
      gotoPage
   } = useTable<UserModel>(
      {
         autoResetPage: false,
         columns: useMemo<Column<UserModel>[]>(
            () => [
               {
                  sticky: "left",
                  disableFilters: true,
                  disableGlobalFilter: true,
                  disableSortBy: true,
                  id: "actions",
                  accessor: (rowData) => rowData,
                  Header: t("admin:actions").toString(),
                  Cell: ActionsCell
               },
               { accessor: "afm", Header: t("admin:afm").toString(), width: 120 },
               {
                  accessor: ({ name }) => {
                     const names = name.split("|");
                     return `${names[0]} ${names[1]}`;
                  },
                  id: "name",
                  Header: t("admin:name").toString(),
                  width: 250
               },
               {
                  id: "created",
                  accessor: ({ created }) => (moment(created).isValid() ? moment(created) : null),
                  Header: t("admin:created").toString(),
                  Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm") : ""),
                  width: 170,
                  sortType: (row1, row2) => moment(new Date(row1.original.created)).diff(new Date(row2.original.created)),
                  sortDescFirst: true
               },
               {
                  Header: t("admin:phone_number_info").toString(),
                  columns: [
                     {
                        accessor: ({ phoneNumberDialCode, phoneNumber }) =>
                           phoneNumberDialCode || phoneNumber ? `${phoneNumberDialCode ? "+" + phoneNumberDialCode : ""} ${phoneNumber || ""}` : "",
                        id: "phoneNumber",
                        Header: t("admin:phone_number").toString(),
                        width: 160,
                        disableSortBy: true
                     },
                     { accessor: "phoneNumberToken", Header: t("admin:phone_number_token").toString(), width: 90, disableSortBy: true },
                     {
                        id: "phoneNumberTokenCreated",
                        accessor: ({ phoneNumberTokenCreated }) => (moment(phoneNumberTokenCreated).isValid() ? moment(phoneNumberTokenCreated) : null),
                        Header: t("admin:phone_number_token_created").toString(),
                        Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm:ss") : ""),
                        width: 190,
                        sortType: (row1, row2) => moment(new Date(row1.original.phoneNumberTokenCreated)).diff(new Date(row2.original.phoneNumberTokenCreated)),
                        sortDescFirst: true
                     },
                     {
                        accessor: "phoneNumberConfirmTries",
                        Header: t("admin:phone_number_confirm_tries").toString(),
                        width: 100,
                        sortType: "number",
                        Cell: ({ value }) => <span className={value > 6 ? "text-danger" : ""}>{value}</span>
                     },
                     {
                        accessor: "phoneNumberConfirmed",
                        Header: t("admin:phone_number_confirmed").toString(),
                        Cell: ({ value }) => t(value ? "admin:yes" : "admin:no").toString(),
                        width: 100,
                        sortType: (row1, row2) => (row1.original.phoneNumberConfirmed ? 1 : 0) - (row2.original.phoneNumberConfirmed ? 1 : 0)
                     }
                  ]
               },
               {
                  Header: t("admin:email_info").toString(),
                  columns: [
                     {
                        accessor: "email",
                        Header: t("admin:email").toString(),
                        width: 300,
                        disableSortBy: true
                     },
                     { accessor: "emailToken", Header: t("admin:email_token").toString(), width: 90, disableSortBy: true },
                     {
                        id: "emailTokenCreated",
                        accessor: ({ emailTokenCreated }) => (moment(emailTokenCreated).isValid() ? moment(emailTokenCreated) : null),
                        Header: t("admin:email_token_created").toString(),
                        Cell: ({ value }: { value: Date }) => (moment(value).isValid() ? moment(value).format("l HH:mm:ss") : ""),
                        width: 190,
                        sortType: (row1, row2) => moment(new Date(row1.original.emailTokenCreated)).diff(new Date(row2.original.emailTokenCreated)),
                        sortDescFirst: true
                     },
                     {
                        accessor: "emailConfirmTries",
                        Header: t("admin:email_confirm_tries").toString(),
                        width: 100,
                        sortType: "number",
                        Cell: ({ value }) => <span className={value > 6 ? "text-danger" : ""}>{value}</span>
                     },
                     {
                        accessor: "emailConfirmed",
                        Header: t("admin:email_confirmed").toString(),
                        Cell: ({ value }) => t(value ? "admin:yes" : "admin:no").toString(),
                        width: 100,
                        sortType: (row1, row2) => (row1.original.emailConfirmed ? 1 : 0) - (row2.original.emailConfirmed ? 1 : 0)
                     }
                  ]
               }
            ],
            []
         ),
         data,
         disableMultiSort: false,
         isMultiSortEvent: useCallback((ev: React.MouseEvent<Element, MouseEvent>) => !!ev.ctrlKey, []),
         initialState: {
            sortBy: [
               {
                  id: "created",
                  desc: true
               }
            ]
         }
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useBlockLayout,
      useSticky
   );

   useEffect(() => {
      if (printable && showPrintable && handlePrint) handlePrint();
   }, [printable, showPrintable, handlePrint]);

   return (
      <SkeletonTheme color="#194172" highlightColor="#274c7a">
         <Card className="admin-panel shadow border-0 mt-md-2 mb-4">
            <div className="p-2">
               <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>
            <Table responsive bordered hover striped {...getTableProps()}>
               <thead>
                  {headerGroups.map((hg, hgi) => (
                     <tr {...hg.getHeaderGroupProps()}>
                        {hg.headers.map((column, ci) => (
                           <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {!column.isSorted ? "" : column.isSortedDesc ? <DownArrow fill="currentColor" /> : <UpArrow fill="currentColor" />}
                              {"  "}
                              {column.render("Header")}
                              {hgi === 0 && ci === 0 ? (
                                 <Button color="success" onClick={() => setShowPrintable(true)}>
                                    {t("admin:print").toString()}
                                 </Button>
                              ) : null}
                           </th>
                        ))}
                     </tr>
                  ))}
               </thead>
               <tbody {...getTableBodyProps()}>
                  {loading ? (
                     <Skeleton height={518} />
                  ) : (
                     page.map((row) => {
                        prepareRow(row);
                        return (
                           <tr {...row.getRowProps()}>
                              {row.cells.map((cell) =>
                                 cell.column.id === "actions" ? (
                                    cell.render("Cell", { onAction: handleAction })
                                 ) : (
                                    <td
                                       {...cell.getCellProps()}
                                       title={
                                          ["number", "string"].includes(typeof cell.value)
                                             ? cell.value.toString()
                                             : typeof cell.value === "boolean"
                                             ? cell.value
                                                ? t("admin:yes").toString()
                                                : t("admin:no").toString()
                                             : isMoment(cell.value)
                                             ? cell.value.format("dddd LL HH:mm:ss")
                                             : ""
                                       }
                                    >
                                       {cell.render("Cell")}
                                    </td>
                                 )
                              )}
                           </tr>
                        );
                     })
                  )}
               </tbody>
            </Table>
            <Pagination className="align-self-center mt-2">
               <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink first onClick={() => gotoPage(0)} />
               </PaginationItem>
               <PaginationItem disabled={!canPreviousPage}>
                  <PaginationLink previous onClick={() => previousPage()} />
               </PaginationItem>
               <InputGroup>
                  <Input
                     type="number"
                     min={1}
                     max={pageCount}
                     value={state.pageIndex + 1}
                     onChange={(ev) => gotoPage(Math.max(parseInt(ev.target.min), Math.min(parseInt(ev.target.value), parseInt(ev.target.max))) - 1)}
                     style={{ width: 80 }}
                  />
                  <InputGroupAddon addonType="append" className="d-none d-sm-inline-block">
                     <InputGroupText>{` / ${pageCount || 1}`}</InputGroupText>
                  </InputGroupAddon>
               </InputGroup>
               <PaginationItem disabled={!canNextPage}>
                  <PaginationLink next onClick={() => nextPage()} />
               </PaginationItem>
               <PaginationItem disabled={!canNextPage}>
                  <PaginationLink last onClick={() => gotoPage(pageCount - 1)} />
               </PaginationItem>
            </Pagination>
         </Card>
         <Modal isOpen={!!userAction?.modalOpen} toggle={handleFinishAction}>
            <ModalBody>{t("admin:are_you_sure").toString()}</ModalBody>
            <ModalFooter className="d-flex justify-end">
               <Button onClick={handleFinishAction}>{t("admin:no").toString()}</Button>
               <Button
                  onClick={async () => {
                     await userAction?.invoke();
                     handleFinishAction();
                  }}
               >
                  {t("admin:yes").toString()}
               </Button>
            </ModalFooter>
         </Modal>
         {showPrintable && (
            <div style={{ display: "none" }}>
               <Printable ref={setPrintable} data={data} globalFilter={state.globalFilter} sort={state.sortBy} />
            </div>
         )}
      </SkeletonTheme>
   );
};

const Admin = () => {
   const [users, setUsers] = useState<UserModel[]>([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      setLoading(true);
      api.get("/Admin/Users")
         .then((res) => {
            setUsers(res.data);
         })
         .finally(() => {
            setLoading(false);
         });
   }, []);

   const handleUpdate = useCallback(
      (id: string, action: string) => async () => {
         try {
            await api.get(`Admin/${action}/${id}`);
            switch (action) {
               case "ConfirmUserEmail":
                  setUsers((users) => {
                     const user = users.find((u) => u.id === id);
                     if (user) return [...users.filter((u) => u.id !== id), { ...user, emailConfirmed: true }];
                     else return users;
                  });
                  break;
               case "ConfirmUserPhoneNumber":
                  setUsers((users) => {
                     const user = users.find((u) => u.id === id);
                     if (user) return [...users.filter((u) => u.id !== id), { ...user, phoneNumberConfirmed: true }];
                     else return users;
                  });
                  break;
               case "ActivateUser":
                  setUsers((users) => {
                     const user = users.find((u) => u.id === id);
                     if (user) return [...users.filter((u) => u.id !== id), { ...user, emailConfirmed: true, phoneNumberConfirmed: true }];
                     else return users;
                  });
                  break;
               case "DeleteUser":
                  setUsers((users) => users.filter((user) => user.id !== id));
                  break;
               default:
                  break;
            }
         } catch (error) {}
      },
      []
   );

   return <AdminTable data={users} getAction={handleUpdate} loading={loading} />;
};

export default Admin;
