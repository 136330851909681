import React, { FC, useEffect, useState } from "react";
import { Api } from "models";
import { Alert, Col, Input, Row } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Hydrometer from "components/MyHydrometers/Hydrometer";
import withError from "hoc/withError/withError";
import { ReduxState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { getHydrometers } from "store/actions";
import { useTranslation } from "react-i18next";

const MyHydrometers: FC = () => {
   const [t] = useTranslation(["hydrometer", "messages", "errors"]);
   const myHydrometers = useSelector<ReduxState, null | Api.Hydrometer.Info[]>((state) => state.hydrometers && state.hydrometers.filter((h) => h.isUpdated));
   const [filter, setFilter] = useState("");
   const [loading, setLoading] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const dispatch = useDispatch();

   useEffect(() => {
      if (!myHydrometers) {
         (async () => {
            try {
               setLoading(true);
               await dispatch(getHydrometers());
               setErrorMsg("");
            } catch (error) {
               setErrorMsg(error.response.data.detail || error || "hydrometer:error");
            } finally {
               setLoading(false);
            }
         })();
      }
   }, []);

   const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

   const loadingView = (
      <SkeletonTheme color="#fff" highlightColor="#f0f2f5">
         <Skeleton height={299} />
      </SkeletonTheme>
   );

   const defaultView = (
      <Row>
         {myHydrometers && myHydrometers.length > 0 ? (
            <>
               <Col xs={12} className="mb-2">
                  <Input type="search" onChange={(e) => setFilter(e.target.value)} placeholder={t("hydrometer:search_hydrometer")} />
               </Col>
               {myHydrometers
                  .filter(
                     (h) =>
                        !filter ||
                        new RegExp(filter, "gi").test(
                           h.address.street +
                              "|" +
                              h.address.street +
                              "|" +
                              h.address.postalCode +
                              "|" +
                              h.contract +
                              "|" +
                              h.eCode +
                              "|" +
                              h.billEmail +
                              "|" +
                              h.billAddress.street +
                              "|" +
                              h.billAddress.region +
                              "|" +
                              h.billAddress.postalCode +
                              "|" +
                              h.identifier +
                              "|" +
                              h.meter +
                              "|" +
                              h.ownerAfm +
                              "|" +
                              h.ownerName
                        )
                  )
                  .map((h, i) => (
                     <Col xs={12} key={"hydro_" + i}>
                        <Hydrometer {...h} />
                     </Col>
                  ))}
            </>
         ) : (
            <Col>
               <h5 className="text-muted d-flex justify-content-center mt-4">{t("messages:no_hydrometers")}</h5>
               <p className="text-muted d-flex justify-content-center mt-2">{t("messages:update_3")}</p>
            </Col>
         )}
      </Row>
   );

   return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(MyHydrometers);
