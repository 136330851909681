import React from "react";
import { Api } from "models";
import { Button, Table } from "reactstrap";
import { getGRDate, getGRCurrency } from "utilities";
import { useTranslation } from "react-i18next";

interface ReceiptProps extends Api.Pay.Receipt {
   type?: "table" | "row";
   onPdf: (url: string, fileName: string) => void;
}

const Receipt: React.FC<ReceiptProps> = (props) => {
   const [t] = useTranslation("receipts");
   return props.type === "row" ? (
      <tr>
         <td>{props.code}</td>
         <td>{getGRDate(new Date(props.issueDate))}</td>
         <td>{getGRCurrency(props.amount)}</td>
         <td>
            <Button color="link" onClick={() => props.onPdf(`/Receipt/${props.findoc}`, props.code)} size="sm" className="text-decoration-none">
               {t("receipts:show")}
            </Button>
         </td>
      </tr>
   ) : (
      <Table bordered>
         <tbody>
            <tr>
               <th scope="row">{t("receipts:code")}</th>
               <td>{props.code}</td>
            </tr>
            <tr>
               <th scope="row">{t("receipts:issue_date")}</th>
               <td>{getGRDate(new Date(props.issueDate))}</td>
            </tr>
            <tr>
               <th scope="row">{t("receipts:amount")}</th>
               <td>{getGRCurrency(props.amount)}</td>
            </tr>
            <tr>
               <th scope="row">{t("receipts:receipt")}</th>
               <td>
                  <Button color="link" onClick={() => props.onPdf(`/Receipt/${props.findoc}`, props.code)} size="sm" className="text-decoration-none">
                     {t("receipts:show")}
                  </Button>
               </td>
            </tr>
         </tbody>
      </Table>
   );
};

export default Receipt;
