import React from "react";
import {Api} from "../../models";
import {FormGroup, FormText, Input, Label} from "reactstrap";
import {useTranslation} from "react-i18next";

interface TermSelectorProps {
    onChange: (termId: string | undefined) => void;
    value: string | undefined;
    terms: Api.Arrangements.Term[] | undefined;
    loading: boolean;
}

const TermSelector: React.FC<TermSelectorProps> = ({onChange, value, terms, loading}) => {
    const [t] = useTranslation("arrangements");

    return (
        <FormGroup>
            <Label for="term_select">
                {t("arrangements:term_select")}
            </Label>
            <Input type="select" id="term_select" name="terms" value={value} disabled={loading} readOnly={loading}
                   onChange={(ev) => onChange(ev.target.value || undefined)}>
                <option value={undefined}></option>
                {terms?.map(({value, discount}) => <option key={`term-${value}`} value={value}>{value} ({discount}%)</option>)}
            </Input>
        </FormGroup>
    );
}

export default TermSelector;