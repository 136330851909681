import React, { useState } from "react";
import { Api } from "models";
import Bill from "../Bill/Bill";
import { CardBody, Card, Collapse, CardTitle, CardSubtitle, Row, Col, CardHeader } from "reactstrap";
import { ReactComponent as ExpandMore } from "assets/material/expand_more.svg";
import { ReactComponent as ExpandLess } from "assets/material/expand_less.svg";
import { useTranslation } from "react-i18next";

interface HydrometerBillsProps extends Api.User.Bill {
   //name: string;
   onSomeAmountChange: (index: number, value: number) => void;
   defaultOpen?: boolean;
}

const HydrometerBills: React.FC<HydrometerBillsProps> = (props) => {
   const [t] = useTranslation("hydrometer");
   const [open, setOpen] = useState(props.defaultOpen);

   return (
      <Card className="mb-4 shadow-sm border-0">
         <CardHeader className={"border-0 bg-transparent" + (open ? " pb-0" : "")} style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
            <CardTitle className="lead">
               {t("hydrometer:hydrometer")} <strong>{props.info.meter}</strong>
               {open ? (
                  <ExpandLess viewBox="0 0 20 20" width="20px" height="20px" className="float-right mr-2" />
               ) : (
                  <ExpandMore viewBox="0 0 20 20" width="20px" height="20px" className="float-right mr-2" />
               )}
            </CardTitle>
            <CardSubtitle className="text-muted">{props.info.identifier || `${props.info.address.street || ""} ${props.info.address.number || ""}`}</CardSubtitle>
         </CardHeader>
         <Collapse isOpen={open}>
            <CardBody className="pt-0">
               <hr />
               <Row>
                  {props.bills.map((bill, index) => (
                     <Col xs={12} md={6}>
                        <Bill {...bill} key={`bill_${index}`} onAmountChange={(val) => props.onSomeAmountChange(index, val)} />
                     </Col>
                  ))}
               </Row>
            </CardBody>
         </Collapse>
      </Card>
   );
};

export default HydrometerBills;
