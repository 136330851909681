import * as moment from "moment";
import "moment/locale/el";

export function getGRCurrency(n: number) {
   return n.toLocaleString("el-GR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €";
}

export function getGRDate(d: moment.MomentInput, format?: string): string {
   const date = moment.default(d);
   return date.isValid() ? date.format(format || "dddd, LL") : "";
   // return moment.parseZone(d, format || "LLL").toString();
   // return (d as Date).toLocaleDateString("el-GR", { year: "numeric", weekday: "long", month: "long", day: "numeric" });
}

export const validations = {
   onlyANSH: (str: string) => !/--|[^Α-Ωα-ωίϊΐόάέύϋΰήώ\w- ./]/gi.test(str) || "errors:only_ansh",
   onlyAlphanumeric: (str: string) => /^[Α-Ωα-ωίϊΐόάέύϋΰήώ\w]+$/.test(str) || "errors:only_alphanumeric",
   onlyDigits: (str: string) => /^\d+$/.test(str.toString()) || "errors:only_digits"
};
