import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import withError from "hoc/withError/withError";
import { Button, ButtonProps } from "reactstrap";
import api from "api";
import { BillModel } from "models";
import { useTranslation } from "react-i18next";

const PayBtn: React.FC<ButtonProps & { bills: BillModel[]; phone: string }> = ({ bills, phone, children, ...btnProps }) => {
   const [t] = useTranslation(["messages", "bill", "errors"]);
   const frame = useRef<HTMLIFrameElement>(null);
   const [paying, setPaying] = useState(false);
   const [error, setError] = useState("");
   const orders = useMemo(
      () =>
         bills ? bills.filter((bill) => bill.payAmount && bill.payAmount > 0).map(({ findoc, payAmount }) => ({ findoc, amount: Math.trunc((payAmount || 0) * 100) })) : [],
      [bills]
   );
   const amount = useMemo(() => orders.reduce((amount, order) => amount + order.amount, 0), [orders]);
   const history = useHistory();

   useEffect(() => {
      const handleMessage = async (ev: MessageEvent) => {
         if (ev.data.cardToken && ev.origin === window.location.origin) {
            const { cardToken, amount } = ev.data;
            try {
               const res = await api.post<{ id: string; error?: string }>("/Pay", { cardToken, orders, amount, phone });
               setPaying(false);
               history.push(`/Receipts/${res.data.id}`, { error: res.data?.error });
            } catch (error) {
               setError(error.response?.data?.detail || "messages:pay_error");
               setPaying(false);
            }
         } else if (ev.data.close) {
            setPaying(false);
         }
      };
      window.addEventListener("message", handleMessage);
      return () => {
         window.removeEventListener("message", handleMessage);
      };
   }, [orders, phone, history]);

   return (
      <>
         {!!error && !paying && <p className="text-danger">{t(error)}</p>}
         <Button
            color="primary"
            {...btnProps}
            disabled={btnProps.disabled || amount <= 0 || paying}
            onClick={() => {
               if (amount > 0) setPaying(true);
            }}
         >
            {t(typeof children === "string" ? children : "bills:pay")}
         </Button>
         {!!amount && amount > 0 && paying && (
            <iframe
               ref={frame}
               title="Pay"
               src={`/PayView?amount=${amount}`}
               style={{ border: "none", width: "100%", height: "100%", overflow: "hidden", zIndex: 10000, position: "fixed", top: "0", left: "0" }}
               lang="el"
            ></iframe>
         )}
      </>
   );
};

export default withError(PayBtn);
