import React from "react";
import { Card, CardTitle, CardSubtitle, Row, Col, CardBody } from "reactstrap";
import { Api } from "models";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface HydrometerProps extends Api.Hydrometer.Info {}

const Hydrometer: React.FC<HydrometerProps> = (props) => {
   const [t] = useTranslation("hydrometer");
   return (
      <Card className="mb-4 shadow-sm border-0">
         <CardBody>
            <CardTitle className="lead">
               {t("hydrometer:hydrometer")} <strong>{props.meter}</strong>
            </CardTitle>
            <CardSubtitle className="text-muted">{props.identifier || `${props.address.street || ""} ${props.address.number || ""}`}</CardSubtitle>
            <hr />
            <dl>
               <Row>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:contract")}</dt>
                     <dd>{props.contract}</dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:meter")}</dt>
                     <dd>{props.meter}</dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:drainage_connected")}</dt>
                     <dd>{props.drainage ? t("hydrometer:yes") : t("hydrometer:no")}</dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:address")}</dt>
                     <dd>
                        {props.address.street} {props.address.number}
                     </dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:bill_address")}</dt>
                     <dd>
                        {props.billAddress.street} {props.billAddress.number}, {props.billAddress.postalCode}, {props.billAddress.region}
                     </dd>
                  </Col>
                  {props.eBill && (
                     <Col xs={12} md={6}>
                        <dt>{t("hydrometer:email")}</dt>
                        <dd>{props.billEmail}</dd>
                     </Col>
                  )}
                  {props.relation == 3 && (
                     <Col xs={12} md={6}>
                        <dt>{t("hydrometer:owner")}</dt>
                        <dd>{props.ownerName}</dd>
                     </Col>
                  )}
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:indebt")}</dt>
                     <dd>{props.user.fullname}</dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:afm_indebt")}</dt>
                     <dd>{props.user.afm}</dd>
                  </Col>
               </Row>
            </dl>
            <Row>
               <Col xs={12} className="d-flex justify-content-end">
                  <Link className="btn btn-link text-info text-decoration-none font-weight-bold mr-1" to={`MyHydrometers/${props.trdr}/Statements`}>
                     {t("hydrometer:statement")}
                  </Link>
               </Col>
            </Row>
         </CardBody>
      </Card>
   );
};

export default Hydrometer;
