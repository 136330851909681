import React, { useEffect, useState } from "react";
import api from "api";
import { Alert } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Statement from "components/MyHydrometers/Statement";
import { Api } from "models";
import withError from "hoc/withError/withError";
import PDFModal from "components/Pdf/PDFModal";
import { useTranslation } from "react-i18next";

interface StatementsProps {
   trdr: string;
}

const Statements: React.FC<StatementsProps> = ({ trdr }) => {
   const [t] = useTranslation(["hydrometer", "errors"]);
   const [statements, setStatements] = useState<Api.Hydrometer.Statements>({});
   const [errorMsg, setErr] = useState("");
   const [loading, setLoading] = useState(true);
   const [pdf, setPdf] = useState<{ url: string; fileName: string }>({ url: "", fileName: "" });

   const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

   const loadingView = (
      <SkeletonTheme color="#194172" highlightColor="#274c7a">
         <Skeleton height={518} />
      </SkeletonTheme>
   );

   useEffect(() => {
      api.post<Api.Hydrometer.Statements>("Hydrometer/Statements", { data: trdr })
         .then((res) => setStatements(res.data))
         .catch((err) => setErr(err.response.data.detail || err || "hydrometer:error"))
         .finally(() => setLoading(false));
   }, [trdr]);

   const defaultView = (
      <>
         {Object.keys(statements).map((s, i) => (
            <Statement
               key={"statement_" + i}
               title={s}
               movements={statements[s]}
               chunckSize={7}
               onPdf={(url, fileName) => {
                  setPdf({ url, fileName });
               }}
            />
         ))}
         <PDFModal {...pdf} onClose={() => setPdf({ url: "", fileName: "" })} />
      </>
   );

   return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(Statements);
