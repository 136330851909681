import React, { FC, useEffect, useState } from "react";
import { Api } from "models";
import { Alert, Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Hydrometer from "components/UpdateHydrometers/Hydrometer";
import withError from "hoc/withError/withError";
import { Link } from "react-router-dom";
import { ReduxState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { getHydrometers, rejectHydrometer } from "store/actions";
import { useTranslation } from "react-i18next";

const UpdateHydrometers: FC = () => {
   const [t] = useTranslation("hydrometer");
   const hydrometers = useSelector<ReduxState, null | Api.Hydrometer.Info[]>((state) => state.hydrometers);
   const [loading, setLoading] = useState(false);
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [rejectReason, setRejectReason] = useState(0);
   const [modalLoading, setModalLoading] = useState(false);
   const [actionTrdr, setActionTrdr] = useState(0);
   const [errorMsg, setErrorMsg] = useState("");
   const [modalErrorMsg, setModalErrorMsg] = useState("");
   const dispatch = useDispatch();

   useEffect(() => {
      if (!hydrometers) {
         (async () => {
            try {
               setLoading(true);
               await dispatch(getHydrometers());
               setErrorMsg("");
            } catch (error) {
               setErrorMsg(error.response.data.detail || error || "hydrometer:error");
            } finally {
               setLoading(false);
            }
         })();
      }
   }, []);

   const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

   const loadingView = (
      <SkeletonTheme color="#fff" highlightColor="#f0f2f5">
         <Skeleton height={299} />
      </SkeletonTheme>
   );

   const defaultView = (
      <>
         <Row>
            <Col xs={12} className="d-flex justify-content-end">
               <Link to="/MyHydrometers/Add" className="btn btn-success mb-3 text-white font-weight-bold">
                  {t("hydrometer:add_hydrometer")}
               </Link>
            </Col>
         </Row>
         <Row>
            {hydrometers && hydrometers.length > 0 ? (
               hydrometers
                  .sort((a, b) => (a.isUpdated ? 1 : 0) - (b.isUpdated ? 1 : 0))
                  .map((h, i) => (
                     <Col xs={12} key={"hydro_" + i}>
                        <Hydrometer
                           {...h}
                           onDelete={() => {
                              setActionTrdr(h.trdr);
                              setRejectReason(0);
                              setDeleteModalOpen(true);
                           }}
                        />
                     </Col>
                  ))
            ) : (
               <Col>
                  <h5 className="text-muted d-flex justify-content-center mt-4">{t("hydrometer:no_hydrometers")}</h5>
                  <p className="text-muted d-flex justify-content-center mt-2">{t("hydrometer:proceed_update")}</p>
               </Col>
            )}
         </Row>
         <Modal
            isOpen={deleteModalOpen}
            toggle={() => {
               if (!modalLoading) {
                  setModalErrorMsg("");
                  setRejectReason(0);
                  setDeleteModalOpen(false);
               }
            }}
         >
            <ModalBody>
               {t("hydrometer:reject_reason")} <br />
               <FormGroup>
                  <Label>{t("hydrometer:reject_reason")}</Label>
                  <InputGroup>
                     <Input type="select" value={rejectReason} onChange={(e) => setRejectReason(parseInt(e.target.value))}>
                        <option hidden value={0}></option>
                        <option value={1}>{t("hydrometer:reject_reason_1")}</option>
                        <option value={2}>{t("hydrometer:reject_reason_2")}</option>
                        <option value={3}>{t("hydrometer:reject_reason_3")}</option>
                        <option value={4}>{t("hydrometer:reject_reason_4")}</option>
                     </Input>
                  </InputGroup>
               </FormGroup>
            </ModalBody>
            <ModalFooter>
               {modalErrorMsg && (
                  <Row>
                     <Col>
                        <div className="text-danger">{t(modalErrorMsg)}</div>
                     </Col>
                  </Row>
               )}
               <Row>
                  <Col>
                     <Button
                        color="danger"
                        disabled={modalLoading || rejectReason <= 0 || rejectReason > 4}
                        onClick={async () => {
                           try {
                              setModalLoading(true);
                              await dispatch(rejectHydrometer(actionTrdr, rejectReason));
                              setRejectReason(0);
                              setDeleteModalOpen(false);
                              setModalErrorMsg("");
                           } catch (error) {
                              setModalErrorMsg(error.response.data?.detail || "hydrometer.error");
                           } finally {
                              setModalLoading(false);
                           }
                        }}
                        className="mr-2"
                     >
                        {t("hydrometer:reject")}
                     </Button>
                     <Button
                        disabled={modalLoading}
                        onClick={() => {
                           setRejectReason(0);
                           setModalErrorMsg("");
                           setDeleteModalOpen(false);
                        }}
                     >
                        {t("hydrometer:cancel")}
                     </Button>
                  </Col>
               </Row>
            </ModalFooter>
         </Modal>
      </>
   );

   return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(UpdateHydrometers);
