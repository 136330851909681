import React from "react";
import Home from "containers/Home/Home";
import * as qs from "query-string";
import ContactInfo from "containers/User/ContactInfo";
import { RouteComponentProps } from "react-router";
import MyHydrometers from "containers/MyHydrometers/MyHydrometers";
import Statements from "containers/MyHydrometers/Statements";
import Bills from "containers/Bills/Bills";
import Receipts from "containers/Receipts/Receipts";
import MyBills from "containers/MyBills/MyBills";
import UpdateHydrometer from "containers/UpdateHydrometers/UpdateHydrometer";
import QuickPayment from "containers/QuickPayment/QuickPayment";
import AddHydrometer from "containers/UpdateHydrometers/AddHydrometer";
import Measurements from "containers/Measurements/Measurements";
import UpdateHydrometers from "containers/UpdateHydrometers/UpdateHydrometers";
import Admin from "containers/Admin/Admin";
import AddArrangement from "./containers/Arrangements/AddArrangement";
import Arrangements from "./containers/Arrangements/Arrangements";
import AddApplication from "./containers/Applications/AddApplication";
import Applications from "./containers/Applications/Applications";

type position = "none" | "nav" | "topbar" | "usermenu1" | "usermenu2";

interface IRoute {
   name?: string;
   position: position;
   path: string;
   exact?: boolean;
   render?: (arg?: any) => JSX.Element;
}

interface IAuthRoute extends IRoute {
   auth: boolean;
   confirmedContactInfo?: boolean;
}

interface IAdminRoute extends IAuthRoute {
   isAdmin: boolean;
}

export const routes: IRoute[] = [
   {
      name: "nav:web_services",
      position: "nav",
      path: "/",
      exact: true,
      render: (routeProps: RouteComponentProps) => <Home />
   },
   {
      name: "nav:quick_payment",
      position: "none",
      path: "/QuickPayment",
      exact: true,
      render: (routeProps: RouteComponentProps) => (
         <QuickPayment
            onSubmitCSECodes={(cseCodes: string) => {
               routeProps.history.push({
                  pathname: "/Bills",
                  search:
                     "?" +
                     qs.stringify(
                        {
                           eCodes: cseCodes
                              .split(",")
                              .map((c) => c.replace(/\s/g, ""))
                              .filter((c, i, arr) => i === arr.indexOf(c))
                        },
                        { arrayFormat: "comma" }
                     )
               });
            }}
         />
      )
   },
   {
      position: "none",
      path: "/Bills",
      exact: false,
      render: (routeProps: RouteComponentProps) => {
         const codes = (qs.parse(routeProps.location.search)["eCodes"] as string)
            .split(",")
            .map((c) => c.trim())
            .filter((c, i, arr) => i === arr.indexOf(c));
         return <Bills {...routeProps} eCodes={codes} />;
      }
   },
   {
      position: "none",
      path: "/Receipts/:id",
      exact: false,
      render: (routeProps: RouteComponentProps) => {
         return <Receipts {...(routeProps.match.params as { id: string })} />;
      }
   },
];

export const authRoutes: IAuthRoute[] = [
   {
      name: "nav:update",
      position: "usermenu1",
      path: "/UpdateHydrometers",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: (routeProps: RouteComponentProps) => <UpdateHydrometers />
   },
   {
      position: "none",
      path: "/MyHydrometers/Update",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: (routeProps: RouteComponentProps) => {
         return <UpdateHydrometer {...(qs.parse(routeProps.location.search) as { cccydrmtr: string; trdr: string; edit: string; meter: string })} />;
      }
   },
   {
      name: "nav:add_hydrometer",
      position: "none",
      path: "/MyHydrometers/Add",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: () => <AddHydrometer />
   },
   {
      name: "nav:my_bills",
      position: "usermenu1",
      path: "/MyBills",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: (routeProps: RouteComponentProps) => <MyBills {...routeProps} />
   },
   {
      name: "nav:my_hydrometers",
      position: "usermenu1",
      path: "/MyHydrometers",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: () => <MyHydrometers />
   },
   {
      position: "none",
      path: "/MyHydrometers/:trdr/Statements",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: (routeProps: RouteComponentProps) => <Statements {...routeProps.match.params} />
   },
   {
      name: "nav:measurements",
      position: "usermenu1",
      path: "/Measurements",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: () => <Measurements />
   },
   {
      name: "nav:arrangements",
      position: "usermenu1",
      path: "/Arrangements",
      exact: true,
      auth: true,
      render: (routeProps) => <Arrangements />
   },
   {
      position: "none",
      path: "/Arrangements/Add",
      exact: true,
      auth: true,
      render: (routeProps) => <AddArrangement {...(qs.parse(routeProps.location.search))} />
   },
   {
      name: "nav:applications",
      position: "usermenu1",
      path: "/Applications",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: () => <Applications />
   },
   {
      position: "none",
      path: "/Applications/Add",
      exact: true,
      auth: true,
      confirmedContactInfo: true,
      render: () => <AddApplication />
   },
   {
      name: "nav:contact_info",
      position: "usermenu2",
      path: "/User/ContactInfo",
      exact: true,
      auth: true,
      render: (routeProps: RouteComponentProps) => <ContactInfo {...(qs.parse(routeProps.location.search) as { returnUrl: string })} />
   }
];

export const adminRoutes: IAdminRoute[] = [
   {
      name: "nav:admin",
      position: "none",
      path: "/Admin",
      exact: true,
      auth: true,
      isAdmin: true,
      render: () => <Admin />
   }
];
