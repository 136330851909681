import React from "react";
import { Card, CardTitle, CardSubtitle, Row, Col, CardBody } from "reactstrap";
import { Api } from "models";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface HydrometerProps extends Api.Hydrometer.Info {
   onDelete: () => void;
}

const Hydrometer: React.FC<HydrometerProps> = (props) => {
   const [t] = useTranslation("hydrometer");
   return (
      <Card className={"mb-4 shadow-sm border-0" + (!props.isUpdated ? " bg-light text-white" : "")}>
         <CardBody style={{ color: props.isUpdated ? undefined : "#9f9f9f" }}>
            <CardTitle className="lead">
               {t("hydrometer:hydrometer")} <strong>{props.meter}</strong>
            </CardTitle>
            {props.isUpdated ? (
               <CardSubtitle className="text-muted">{props.identifier || `${props.address.street || ""} ${props.address.number || ""}`}</CardSubtitle>
            ) : (
               <CardSubtitle className="font-weight-bold text-danger">{t("hydrometer:need_update")}</CardSubtitle>
            )}
            <hr />
            <dl>
               <Row>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:contract")}</dt>
                     <dd>{props.contract}</dd>
                  </Col>
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:meter")}</dt>
                     <dd>{props.meter}</dd>
                  </Col>
                  {props.isUpdated && (
                     <Col xs={12} md={6}>
                        <dt>{t("hydrometer:drainage_connected")}</dt>
                        <dd>{props.drainage ? t("hydrometer:yes") : t("hydrometer:no")}</dd>
                     </Col>
                  )}
                  <Col xs={12} md={6}>
                     <dt>{t("hydrometer:address")}</dt>
                     <dd>
                        {props.address.street} {props.address.number}
                     </dd>
                  </Col>
                  {props.isUpdated && (
                     <>
                        <Col xs={12} md={6}>
                           <dt>{t("hydrometer:bill_address")}</dt>
                           <dd>
                              {props.billAddress.street} {props.billAddress.number}, {props.billAddress.postalCode}, {props.billAddress.region}
                           </dd>
                        </Col>
                        {props.eBill && (
                           <Col xs={12} md={6}>
                              <dt>{t("hydrometer:bill_email")}</dt>
                              <dd>{props.billEmail}</dd>
                           </Col>
                        )}
                        {props.relation == 3 && (
                           <Col xs={12} md={6}>
                              <dt>{t("hydrometer:owner")}</dt>
                              <dd>{props.ownerName}</dd>
                           </Col>
                        )}
                        <Col xs={12} md={6}>
                           <dt>{t("hydrometer:indebt")}</dt>
                           <dd>{props.user.fullname}</dd>
                        </Col>
                        <Col xs={12} md={6}>
                           <dt>{t("hydrometer:afm_indebt")}</dt>
                           <dd>{props.user.afm}</dd>
                        </Col>
                     </>
                  )}
               </Row>
            </dl>
            <Row>
               {props.isUpdated ? (
                  <Col xs={12} className="d-flex justify-content-end">
                     <Link
                        className="btn btn-link text-success text-decoration-none font-weight-bold"
                        to={`MyHydrometers/Update?trdr=${props.trdr}&cccydrmtr=${props.cccydrmtr}&edit=true&meter=${encodeURIComponent(props.meter)}`}
                     >
                        {t("hydrometer:edit")}
                     </Link>
                  </Col>
               ) : (
                  <Col xs={12} className="d-flex justify-content-end">
                     <div className="btn btn-link text-danger text-decoration-none font-weight-bold mr-1" onClick={props.onDelete}>
                        {t("hydrometer:reject")}
                     </div>
                     <Link
                        className="btn btn-link text-success text-decoration-none font-weight-bold"
                        to={`MyHydrometers/Update?trdr=${props.trdr}&cccydrmtr=${props.cccydrmtr}&meter=${encodeURIComponent(props.meter)}`}
                     >
                        {t("hydrometer:update")}
                     </Link>
                  </Col>
               )}
            </Row>
         </CardBody>
      </Card>
   );
};

export default Hydrometer;
