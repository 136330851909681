import React from "react";
import { Alert } from "reactstrap";
import Warning from "../../Warning";
import { ServerError } from "api";
import { useTranslation } from "react-i18next";

interface ErrorInfoProps {
   error?: Error | Warning;
   show?: boolean;
}

const ErrorInfo: React.FC<ErrorInfoProps> = (props) => {
   const [t] = useTranslation(["messages", "errors"]);
   if (props.error instanceof Warning) return <Alert color="warning">{t(props.error.message)}</Alert>;
   else if (props.error instanceof ServerError)
      return (
         <Alert color="danger">
            <h5>{t("messages:error")}</h5>
            <p>{t(props.error.message)}</p>
         </Alert>
      );
   else
      return (
         <Alert color="danger">
            <h5>{t("messages:error")}</h5>
            {props.show && props.error ? <p>{t(props.error.message)}</p> : null}
         </Alert>
      );
};

export default ErrorInfo;
