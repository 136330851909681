import React, { useState } from "react";
import {
   Navbar,
   NavbarBrand,
   NavbarToggler,
   Nav,
   NavItem,
   Container,
   Collapse,
   NavLink,
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   Alert,
   Button
} from "reactstrap";
import { NavLink as RLink, Link, useHistory, useLocation } from "react-router-dom";
import deyarLogo from "assets/deyar_logo_2.png";
import "./Layout.scss";
import { routes, authRoutes } from "routes";
import { ReactComponent as AccountCircleIcon } from "assets/material/account_circle.svg";
import { ReactComponent as MenuIcon } from "assets/material/menu.svg";
import { ReactComponent as CloseIcon } from "assets/material/close.svg";
import * as qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "store";
import { UserInfoModel } from "models";
import { logout } from "store/actions";
import { useTranslation } from "react-i18next";
import { Gb, Gr } from "react-flags-select";

const getFlag = (code: string) => {
   switch (code) {
      case "el":
         return <Gr className="mr-2" />;
      case "en":
         return <Gb className="mr-2" />;
      default:
         return null;
   }
};

const getLang = (code: string) => {
   switch (code) {
      case "el":
         return "Ελληνικά";
      case "en":
         return "English";
      default:
         return "";
   }
};

interface LayoutState {
   menuOpen: boolean;
   footerHeight: number;
   loading: boolean;
}

const Layout: React.FC = ({ children }) => {
   const [t, i18n] = useTranslation(["nav"]);
   const [state, setState] = useState<LayoutState>({
      loading: false,
      menuOpen: false,
      footerHeight: 10
   });
   const dispatch = useDispatch();
   const isAuthenticated = useSelector<ReduxState>((state) => state.isAuthenticated);
   const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
   const userNames = userInfo?.name && userInfo.name.split("|");
   const [userMenuOpen, setUserMenuOpen] = useState(false);
   const [langMenuOpen, setLangMenuOpen] = useState(false);
   const fullname = `${userNames && userNames[0]} ${userNames && (userNames[1] || "")}`;

   const history = useHistory();
   const location = useLocation();

   const langSelector = (
      <Dropdown isOpen={langMenuOpen} toggle={() => setLangMenuOpen((langMenuOpen) => !langMenuOpen)} style={{ zIndex: 1500 }}>
         <DropdownToggle outline color="success" caret className="d-flex align-items-center mr-3">
            {getFlag(i18n.language)} {getLang(i18n.language) + "  "}
         </DropdownToggle>
         <DropdownMenu>
            {(i18n.options?.supportedLngs || [])
               .filter((lng) => getLang(lng))
               .map((lng) => (
                  <DropdownItem key={lng} className="d-flex align-items-center" onClick={() => i18n.changeLanguage(lng)}>
                     {getFlag(lng)} {getLang(lng)}
                  </DropdownItem>
               ))}
         </DropdownMenu>
      </Dropdown>
   );

   const topBar = (
      <Container style={{ background: "#194172" }} fluid className="text-white d-none d-md-block">
         <Container className="py-2 d-flex justify-content-end align-items-center">
            {state.loading ? null : isAuthenticated ? (
               <Dropdown isOpen={userMenuOpen} toggle={() => setUserMenuOpen((userMenuOpen) => !userMenuOpen)} style={{ zIndex: 1050 }}>
                  <DropdownToggle outline color="success" caret>
                     {"My ΔΕΥΑΡ  "}
                  </DropdownToggle>
                  <DropdownMenu>
                     <DropdownItem style={{ fontSize: "1rem", color: "#194172" }} header className="d-flex align-items-center">
                        <AccountCircleIcon className="mr-4" style={{ fill: "currentColor" }} />
                        {fullname}
                     </DropdownItem>
                     <DropdownItem divider />
                     {authRoutes
                        .filter((r) => r.auth === isAuthenticated && r.position === "usermenu1")
                        .map((r, i) => (
                           <RLink exact={r.exact} className="text-decoration-none" key={"usermenu1_" + i} to={r.path}>
                              <DropdownItem>{r.name && t(r.name)}</DropdownItem>
                           </RLink>
                        ))}
                     {authRoutes.filter((r) => r.auth === isAuthenticated && r.position === "usermenu1").length > 0 && <DropdownItem divider />}
                     {authRoutes
                        .filter((r) => r.auth === isAuthenticated && r.position === "usermenu2")
                        .map((r, i) => (
                           <RLink exact={r.exact} className="text-decoration-none" key={"usermenu2_" + i} to={r.path}>
                              <DropdownItem>{r.name && t(r.name)}</DropdownItem>
                           </RLink>
                        ))}
                     <DropdownItem onClick={() => dispatch(logout(() => history.push("/")))}>{t("nav:logout")}</DropdownItem>
                  </DropdownMenu>
               </Dropdown>
            ) : (
               <>
                  <Button outline color="success" onClick={() => (window.location.href = "/api/User/ExternalLogin?provider=TaxisNet")}>
                     {t("nav:taxis_login")}
                  </Button>
                  {authRoutes
                     .filter((r) => r.auth === isAuthenticated && r.position === "topbar")
                     .map((r, i) => (
                        <Link key={"topbar_" + i} className="btn btn-outline-success mx-1" to={r.path}>
                           {r.name && t(r.name)}
                        </Link>
                     ))}
               </>
            )}
         </Container>
      </Container>
   );

   const navBar = (
      <Navbar className="shadow-sm py-0" color="white" expand="md">
         {/*h-100 d-flex justify-content-between */}
         <Container className="align-items-stretch align-content-center">
            <NavbarBrand className="p-0" onClick={() => history.push("/")}>
               <img className="my-2 d-none d-md-block" src={deyarLogo} alt="ΔΕΥΑΡ" height="100px" width="200px" />
               <img className="my-2 d-md-none" src={deyarLogo} alt="ΔΕΥΑΡ" height="70px" width="140px" />
            </NavbarBrand>
            <NavbarToggler className="mr-2 h-auto" style={{ outline: 0 }} onClick={() => setState((prevState) => ({ ...prevState, menuOpen: !prevState.menuOpen }))}>
               {state.menuOpen ? (
                  <CloseIcon viewBox="0 0 20 20" width="20px" height="20px" style={{ fill: "#194172" }} />
               ) : (
                  <MenuIcon viewBox="0 0 20 20" width="20px" height="20px" style={{ fill: "#194172" }} />
               )}
            </NavbarToggler>
            <Collapse isOpen={state.menuOpen} navbar onClick={() => setState((prevState) => ({ ...prevState, menuOpen: false }))}>
               <Nav className="mr-auto h-100 d-none d-md-flex" navbar>
                  {[...routes.filter((r) => r.position === "nav"), ...authRoutes.filter((r) => r.auth === isAuthenticated && r.position === "nav")].map((r, i) => (
                     <NavItem className="mr-2 h-100" key={"nav_" + i}>
                        <RLink exact={r.exact} className="custom-nav-link nav-link h-100" to={r.path}>
                           {r.name && t(r.name)}
                        </RLink>
                     </NavItem>
                  ))}
                  <NavItem>
                     <NavLink href="https://deyar.gr/odigies-my-deyar/" target="_blank" className="custom-nav-link nav-link h-100">
                        {t("nav:instructions")}
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink href="https://deyar.gr/privacy-notice/" target="_blank" className="custom-nav-link nav-link h-100 ml-2">
                        {t("nav:privacy_policy")}
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink href="https://deyar.gr/epikoinonia/" target="_blank" className="custom-nav-link nav-link h-100 ml-2">
                        {t("nav:contact")}
                     </NavLink>
                  </NavItem>
               </Nav>

               <Nav className="mr-auto h-100 d-md-none" navbar>
                  <NavItem>
                     <RLink exact to="/" className="custom-nav-link nav-link h-100">
                        {t("nav:home")}
                     </RLink>
                  </NavItem>
                  <NavItem>
                     <NavLink href="https://deyar.gr/odigies-my-deyar/" target="_blank" className="custom-nav-link nav-link h-100">
                        {t("nav:instructions")}
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink href="https://deyar.gr/privacy-notice/" target="_blank" className="custom-nav-link nav-link h-100">
                        {t("nav:privacy_policy")}
                     </NavLink>
                  </NavItem>
                  <NavItem>
                     <NavLink href="https://deyar.gr/epikoinonia/" target="_blank" className="custom-nav-link nav-link h-100 ml-2">
                        {t("nav:contact")}
                     </NavLink>
                  </NavItem>
                  {state.loading ? null : isAuthenticated ? (
                     <>
                        <NavItem className="d-flex align-items-center justify-content-center">
                           <NavLink className="custom-nav-link" href="#" style={{ fontSize: "1rem", color: "#a1b0c7" }}>
                              <AccountCircleIcon className="mr-1" style={{ fill: "currentColor" }} />
                              {fullname}
                           </NavLink>
                        </NavItem>
                        {authRoutes
                           .filter((r) => r.auth === isAuthenticated && r.position === "usermenu1")
                           .map((r, i) => (
                              <NavItem key={"nav_" + i}>
                                 <RLink exact={r.exact} className="custom-nav-link nav-link h-100" to={r.path}>
                                    {r.name && t(r.name)}
                                 </RLink>
                              </NavItem>
                           ))}
                        {authRoutes
                           .filter((r) => r.auth === isAuthenticated && r.position === "usermenu2")
                           .map((r, i) => (
                              <NavItem key={"nav_" + i}>
                                 <RLink exact={r.exact} className="custom-nav-link nav-link h-100" to={r.path}>
                                    {r.name && t(r.name)}
                                 </RLink>
                              </NavItem>
                           ))}
                        <NavItem>
                           <NavLink href="#" className="custom-nav-link" onClick={() => dispatch(logout(() => history.push("/")))}>
                              {t("nav:logout")}
                           </NavLink>
                        </NavItem>
                     </>
                  ) : (
                     <>
                        <NavItem className="mr-2 h-100">
                           <NavLink href="/api/User/ExternalLogin?provider=TaxisNet" className="custom-nav-link">
                              {t("nav:taxis_login")}
                           </NavLink>
                        </NavItem>
                        {authRoutes
                           .filter((r) => r.auth === isAuthenticated && r.position === "topbar")
                           .map((r, i) => (
                              <NavItem key={"ua_nav_" + i}>
                                 <RLink exact={r.exact} className="custom-nav-link nav-link h-100" to={r.path}>
                                    {r.name && t(r.name)}
                                 </RLink>
                              </NavItem>
                           ))}
                     </>
                  )}
               </Nav>
            </Collapse>
         </Container>
      </Navbar>
   );

   const error = qs.parse(location.search).error;
   const errorView = (
      <Alert
         color="danger"
         isOpen={!!error}
         toggle={() => {
            let search = qs.parse(location.search);
            delete search.error;
            let _location = { ...location };
            _location.search = qs.stringify(search);
            history.replace(_location);
         }}
      >
         {error}
      </Alert>
   );

   const msg = qs.parse(location.search).msg;
   const msgView = (
      <Alert
         color="primary"
         isOpen={!!msg}
         toggle={() => {
            let search = qs.parse(location.search);
            delete search.msg;
            let _location = { ...location };
            _location.search = qs.stringify(search);
            history.replace(_location);
         }}
      >
         {msg}
      </Alert>
   );

   const footerConntet = (
      <>
         <div>
            &copy; 2020&nbsp;
            <a className="text-decoration-none" href="https://rodiakipliroforiki.gr" rel="noopener noreferrer" target="_blank">
               Ροδιακή Πληροφορική
            </a>
            &nbsp;All Rights Reserved.
         </div>
      </>
   );

   const footer = (
      <>
         <div id="footerSpace" style={{ height: state.footerHeight + 30 + "px" }}></div>
         <footer className="container-fluid position-absolute bg-light" style={{ bottom: 0 }}>
            <Container className="d-flex justify-content-center align-items-center" style={{ fontSize: "0.75rem" }}>
               <div className="d-none d-sm-flex justify-content-center align-items-center flex-column">{footerConntet}</div>
               <div className="d-flex justify-content-center align-items-center d-sm-none flex-column" style={{ fontSize: "0.5rem" }}>
                  {footerConntet}
               </div>
            </Container>
         </footer>
      </>
   );

   return (
      <div className="position-relative mh-100 min-vh-100">
         {topBar}
         {navBar}
         <Container className="mt-4">
            {error ? errorView : null}
            {msg ? msgView : null}
            {children}
         </Container>
         {footer}
      </div>
   );
};

export default Layout;
