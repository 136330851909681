import React, { FC, useEffect, useState } from "react";
import { Api } from "models";
import { Alert, Col, Row } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Hydrometer from "components/Measurements/Hydrometer";
import withError from "hoc/withError/withError";
import { ReduxState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { getHydrometers } from "store/actions";
import { useTranslation } from "react-i18next";

const Measurements: FC = () => {
   const [t] = useTranslation(["messages", "errors"]);
   const hydrometers = useSelector<ReduxState, null | Api.Hydrometer.Info[]>((state) => state.hydrometers);
   const [loading, setLoading] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const dispatch = useDispatch();

   useEffect(() => {
      if (!hydrometers) {
         (async () => {
            try {
               setLoading(true);
               await dispatch(getHydrometers());
               setErrorMsg("");
            } catch (error) {
               setErrorMsg(error.response.data.detail || error || "hydrometer:error");
            } finally {
               setLoading(false);
            }
         })();
      }
   }, []);

   const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

   const loadingView = (
      <SkeletonTheme color="#fff" highlightColor="#f0f2f5">
         <Skeleton height={299} />
      </SkeletonTheme>
   );

   const updatedHydrometers =
      hydrometers && hydrometers.length > 0 && hydrometers.filter((h) => h.isUpdated).sort((b, a) => (a.measurement.isOpen ? 1 : 0) - (b.measurement.isOpen ? 1 : 0));

   const defaultView = (
      <Row>
         {updatedHydrometers && updatedHydrometers.length > 0 ? (
            updatedHydrometers.map((h, i) => (
               <Col xs={12} key={"hydro_" + i}>
                  <Hydrometer {...h} />
               </Col>
            ))
         ) : (
            <Col>
               <h5 className="text-muted d-flex justify-content-center mt-4">{t("messages:update_2")}</h5>
            </Col>
         )}
      </Row>
   );

   return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(Measurements);
