import React, { FC, useState } from "react";
import { Button, Col, Collapse, Container, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Row } from "reactstrap";
import ECodeHelpImg from "assets/eCode_help.jpg";
import { useTranslation } from "react-i18next";

interface QuickPaymentProps {
   onSubmitCSECodes: (cseCodes: string) => void;
}

const QuickPayment: FC<QuickPaymentProps> = (props) => {
   const [t] = useTranslation(["quick_payment", "temp"]);
   const [cseCodes, setCSECodes] = useState("");
   const [eCodeHelpOpen, setECodeHelpOpen] = useState(false);
   return (
      <Container>
         <h1 className="display-5-responsive">{t("quick_payment:title")}</h1>
         <p>{t("quick_payment:instructions")}</p>
         <h1 className="display-5-responsive">{t("temp:title")}</h1>
         <p>{t("temp:instructions")}</p>
         <Row>
            <Col xs={12} sm={8} md={6}>
               <Form
                  onSubmit={(ev) => {
                     ev.preventDefault();
                     props.onSubmitCSECodes(cseCodes);
                  }}
               >
                  <FormGroup>
                     <InputGroup>
                        <Input
                           pattern="^(?:\s*(?:(?:\d{15,20})|(?:RF(?:\d\s?){23, 60}))\s*(?:$|,))+"
                           required
                           type="text"
                           value={cseCodes}
                           onChange={(ev) => setCSECodes(ev.target.value)}
                           title={t("quick_payment:seperate")}
                           placeholder={t("quick_payment:insert")}
                        />
                        <InputGroupAddon addonType="append">
                           <Button color="primary" type="submit">
                              {t("quick_payment:continue")}
                           </Button>
                        </InputGroupAddon>
                     </InputGroup>
                     <FormText>
                        {!eCodeHelpOpen ? (
                           <>
                              {t("quick_payment:press")}
                              <span
                                 className="text-primary"
                                 onClick={() => {
                                    setECodeHelpOpen(true);
                                 }}
                                 style={{ cursor: "pointer" }}
                              >
                                 {t("quick_payment:here")}
                              </span>
                           </>
                        ) : (
                           <span
                              className="text-primary"
                              onClick={() => {
                                 setECodeHelpOpen(false);
                              }}
                              style={{ cursor: "pointer" }}
                           >
                              {t("quick_payment:retract")}
                           </span>
                        )}
                     </FormText>
                  </FormGroup>
               </Form>
            </Col>
            <Col xs={12}>
               <Collapse isOpen={eCodeHelpOpen}>
                  <img src={ECodeHelpImg} alt="" style={{ maxHeight: "100%", maxWidth: "100%" }} className="shadow" />
               </Collapse>
            </Col>
         </Row>
      </Container>
   );
};

export default QuickPayment;
