import React, { FC, useEffect, useState } from "react";
import { Button, Container, Modal, Spinner } from "reactstrap";
import { Document, Page } from "react-pdf";
import { ReactComponent as DownloadIcon } from "assets/material/download.svg";
import "./PDFModal.scss";
import { useTranslation } from "react-i18next";
import { isBrowser } from "react-device-detect";
import axios from "axios";

interface PdfProps {
   url: string;
   fileName: string;
   onClose: () => void;
}

const PDFModal: FC<PdfProps> = ({ url, fileName, onClose }) => {
   const [t] = useTranslation("pdf");
   const [pdfWidth, setPdfWidth] = useState(0);
   const [pdfUrl, setPdfUrl] = useState("");
   const [pdf, setPdf] = useState<any>(null);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (url) {
         (async () => {
            setLoading(true);
            const blob = (await axios.get<Blob>(url, { responseType: "blob" })).data;
            if (blob) setPdfUrl(URL.createObjectURL(blob));
            else setPdfUrl("");
            setLoading(false);
         })();
         return () => {
            URL.revokeObjectURL(pdfUrl);
         };
      } else setPdfUrl("");
   }, [url]);

   const loadingView = (
      <div className="text-white">
         <Spinner size="sm" /> {"  "}
         {t("pdf:loading")}
      </div>
   );
   const loadErrorView = <div className="text-danger">{t("pdf:error")}</div>;

   return (
      <Modal size="lg" isOpen={!!url} toggle={onClose} className="pdf-modal">
         <div
            className="border-aqua"
            ref={(ref: any) => {
               setPdfWidth(ref?.getBoundingClientRect().width || 0);
            }}
         >
            {loading ? (
               loadingView
            ) : (
               <Document className="w-100 h-100" renderMode="svg" loading={loadingView} error={loadErrorView} file={pdfUrl} onLoadSuccess={setPdf}>
                  {pdf?.numPages && new Array(pdf.numPages).fill(0).map((_, pageIndex) => <Page pageIndex={pageIndex} width={pdfWidth} />)}
               </Document>
            )}
         </div>
         {isBrowser && pdf && (
            <Container className="my-2 mx-0 px-0">
               <Button color="primary" href={pdfUrl} download={`${fileName}.pdf`} className="container-fluid" disabled={!pdfUrl}>
                  <DownloadIcon fill="currentColor" /> {t("pdf:download")}
               </Button>
            </Container>
         )}
      </Modal>
   );
};

export default PDFModal;

