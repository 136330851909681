import React, { Fragment, useState, useEffect } from "react";
import { Api } from "models";
import { Table, Card, CardBody, Collapse, InputGroup, InputGroupAddon, Input, InputGroupText, Container, CardSubtitle, CardTitle, Alert } from "reactstrap";
import { getGRCurrency, getGRDate } from "utilities";
import { ReactComponent as ExpandMore } from "assets/material/expand_more.svg";
import { ReactComponent as ExpandLess } from "assets/material/expand_less.svg";
import { useTranslation } from "react-i18next";

interface BillProps extends Api.Bill.Info {
   type?: "row" | "card";
   className?: string;
   onAmountChange: (amount: number) => void;
}

const Bill: React.FC<BillProps> = (props) => {
   const [t] = useTranslation(["bills", "hydrometer"]);
   const [open, setOpen] = useState(false);
   const [amount, setAmount] = useState(props.totalDebt);
   const [includeAmount, setIncludeAmount] = useState(true && props.totalDebt > 0);
   useEffect(() => {
      props.onAmountChange(amount * (includeAmount ? 1 : 0));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [amount, includeAmount]);

   const amountInput = () => (
      <InputGroup>
         <InputGroupAddon addonType="prepend">€</InputGroupAddon>
         <Input
            type="number"
            min={0}
            max={props.totalDebt}
            step={0.5}
            value={amount}
            onChange={(ev) => {
               if (parseFloat(ev.target.value) > parseFloat(ev.target.max)) ev.target.value = ev.target.max;
               else if (parseFloat(ev.target.value) < parseFloat(ev.target.min)) ev.target.value = ev.target.min;
               else if (parseFloat(ev.target.value) > Math.floor(parseFloat(ev.target.value) * 100) / 100)
                  ev.target.value = (Math.floor(parseFloat(ev.target.value) * 100) / 100).toFixed(2);
               else if (parseFloat(ev.target.value).toFixed(2).length < ev.target.value.length) ev.target.value = parseFloat(ev.target.value).toFixed(2);
               setAmount(parseFloat(ev.target.value));
            }}
         />
         <InputGroupAddon addonType="append">
            <InputGroupText>
               <Input checked={includeAmount} onChange={(ev) => setIncludeAmount(ev.target.checked)} addon type="checkbox" aria-label="Should the amount be included" />
            </InputGroupText>
         </InputGroupAddon>
      </InputGroup>
   );

   const getBillObject = () => {
      //common
      let res: any = {};
      if (props.eCode) res[t("hydrometer:eCode")] = props.eCode;
      if (props.contract) res[t("hydrometer:contract")] = props.contract;
      if (props.meter) res[t("hydrometer:meter")] = props.meter;
      res[t("bills:doc_code")] = props.code;
      if (props.issueDate) res[t("bills:issue_date")] = getGRDate(new Date(props.issueDate), "ll");
      if (props.billType === 0) {
         if (props.lastCharge) res[t("bills:bill_balance")] = getGRCurrency(props.lastCharge);
         res[t("bills:due_date")] = getGRDate(new Date(props.dueDate), "ll");
         if (props.dueAmount) {
            if (props.dueAmount > 0) {
               res[t("bills:due_amount")] = getGRCurrency(props.dueAmount);
            } else if (props.dueAmount < 0) {
               Object.assign(res, {
                  [t("credit_amount")]: getGRCurrency(props.dueAmount)
               });
            }
         }
      } else if (props.billType === 1) {
         if (props.settlement) res[t("bills:inst_balance")] = getGRCurrency(props.settlement);
         if (props.lastCharge) res[t("bills:current_installment")] = getGRCurrency(props.lastCharge);
         if (!props.eCode) res[t("bills:installment_due_date")] = getGRDate(new Date(props.dueDate), "ll");
         if (props.dueAmount) {
            if (props.dueAmount > 0) {
               res[t("bills:due_installments")] = getGRCurrency(props.dueAmount);
            } else if (props.dueAmount < 0) {
               res[t("bills:credit_amount")] = getGRCurrency(props.dueAmount);
            }
         }
      }
      if (props.totalDebt > 0) {
         Object.assign(res, {
            [t("bills:debt_sum")]: getGRCurrency(props.totalDebt),
            [t("bills:pay_amount")]: amountInput()
         });
      }
      return res;
   };

   const billTable = () => {
      const billObj: any = getBillObject();
      return (
         <>
            <div className="d-none d-sm-block table-responsive-sm">
               <Table>
                  <tbody>
                     {Object.keys(billObj).map((k, i) => (
                        <tr key={"bill_row_" + i}>
                           <th scope="row">{k}</th>
                           <td>{billObj[k]}</td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
            <div className="d-sm-none">
               <Table>
                  <tbody>
                     {Object.keys(billObj).map((k, i) => (
                        <Fragment key={"bill_r_" + i}>
                           <tr>
                              <th scope="row">{k}</th>
                           </tr>
                           <tr>
                              <td>{billObj[k]}</td>
                           </tr>
                        </Fragment>
                     ))}
                  </tbody>
               </Table>
            </div>
         </>
      );
   };

   const errorView = (
      <Alert color="warning" isOpen={!open} toggle={() => setOpen(true)}>
         {props.error}
      </Alert>
   );

   const defaultView =
      props.type === "row" ? (
         <tr>
            <td className="pl-0">
               <div className="pl-0 py-1 btn btn-link text-decoration-none" onClick={() => setOpen(!open)}>
                  {props.billType === 0 ? t("bills:bill_amount") : props.billType === 1 ? t("bills:installment_amount") : null} (
                  {props.totalDebt > 0 ? getGRCurrency(props.totalDebt) : t("bills:paid")})<small>{props.issueDate && getGRDate(new Date(props.issueDate))}</small>
                  {open ? (
                     <ExpandLess viewBox="0 0 20 20" width="20px" height="20px" className="float-right mr-2" />
                  ) : (
                     <ExpandMore viewBox="0 0 20 20" width="20px" height="20px" className="float-right mr-2" />
                  )}
               </div>
               <Collapse className="mt-2" isOpen={open}>
                  {billTable()}
               </Collapse>
            </td>
         </tr>
      ) : (
         <Card className={"mt-2 " + props.className}>
            <CardBody>
               <Container className="pl-0" fluid>
                  <CardTitle className="lead">
                     <strong>
                        {props.billType === 0 ? t("bills:bill_amount") : props.billType === 1 ? t("bills:installment_amount") : null} (
                        {props.totalDebt > 0 ? getGRCurrency(props.totalDebt) : t("bills:paid")})
                     </strong>
                  </CardTitle>
                  <CardSubtitle>{props.issueDate ? `${t("bills:issued")} ${getGRDate(new Date(props.issueDate), "LL")}` : props.eCode}</CardSubtitle>
               </Container>
               <Container className="mt-2 pl-0">
                  {open ? null : props.totalDebt > 0 ? (
                     <>
                        <hr />
                        {t("pay_amount")}
                        {amountInput()}
                     </>
                  ) : (
                     <>
                        <hr /> {t("bills:no_debt")}
                     </>
                  )}
               </Container>
            </CardBody>
            <Collapse isOpen={open}>{billTable()}</Collapse>
            <div className="btn btn-link text-decoration-none" color="link" onClick={() => setOpen(!open)}>
               {open ? t("bills:less") : t("bills:more")}
            </div>
         </Card>
      );

   return props.error ? errorView : defaultView;
};

export default Bill;
