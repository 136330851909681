import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { Api, UserInfoModel } from "models";

export type ReduxState = {
   isAuthenticated: boolean;
   userInfo: null | UserInfoModel;
   hydrometers: null | Api.Hydrometer.Info[];
};

export default createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
