import api from "api";
import { AxiosError } from "axios";
import { Api, ConfirmUserInfoModel, UpdateUserInfoModel, UserInfoModel } from "models";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ReduxState } from "store";
import types from "./types";

type MyAsyncAction<T = void> = ThunkAction<Promise<T>, ReduxState, undefined, AnyAction>;

const handleError = async (error: AxiosError, dispatch: ThunkDispatch<ReduxState, undefined, AnyAction>, getState: () => ReduxState) => {
   if (error.response) {
      const status = error.response.status;
      switch (status) {
         case 401:
            dispatch({ type: types.LOGOUT });
            break;
         default:
            throw error;
      }
   }
};

export const getUserInfo: () => MyAsyncAction = () => async (dispatch, getState) => {
   try {
      const infoRes = await api.get<UserInfoModel>("/User/Info");
      dispatch({ type: types.SET_USER_INFO, payload: infoRes.data });
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const checkAuthenticated: () => MyAsyncAction = () => async (dispatch, getState) => {
   try {
      const authRes = await api.get<{ isAuthenticated: boolean }>("/User/IsAuthenticated");
      if (authRes.data.isAuthenticated) {
         dispatch({ type: types.LOGIN });
         await dispatch(getUserInfo());
         await dispatch(getHydrometers());
      } else dispatch({ type: types.LOGOUT });
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const confirm: (model: ConfirmUserInfoModel) => MyAsyncAction = (model) => async (dispatch, getState) => {
   try {
      await api.post<{ errors?: any }>("/User/Confirm", model);
   } catch (error) {
      await handleError(error, dispatch, getState);
   } finally {
      try {
         await dispatch(getUserInfo());
         await dispatch(getHydrometers());
      } catch {}
   }
};

export const update: (model: UpdateUserInfoModel) => MyAsyncAction = (model) => async (dispatch, getState) => {
   try {
      await api.post<{ isAuthenticated: boolean }>("/User/Update", model);
      await dispatch(getUserInfo());
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const logout: (onLogout: () => void) => MyAsyncAction = (onLogout: () => void) => async (dispatch, getState) => {
   try {
      await api.get("/User/Logout");
      onLogout();
      dispatch({ type: types.LOGOUT });
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const getHydrometers: () => MyAsyncAction = () => async (dispatch, getState) => {
   try {
      const hydrometers = (await api.get<Api.Hydrometer.Info[]>("/User/Hydrometers")).data;
      dispatch({ type: types.SET_HYDROMETERS, payload: hydrometers });
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const updateHydrometer: (model: Partial<Api.Hydrometer.Update>) => MyAsyncAction = (model) => async (dispatch, getState) => {
   try {
      await api.post("/Hydrometer/Update", { data: model });
      await dispatch(getHydrometers());
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const rejectHydrometer: (trdr: number, reason: number) => MyAsyncAction = (trdr, reason) => async (dispatch, getState) => {
   try {
      await api.post("Hydrometer/Reject", { data: { trdr, reason } });
      await dispatch(getHydrometers());
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};

export const addMeasurement: (trdr: number, measurement: number) => MyAsyncAction = (trdr, measurement) => async (dispatch, getState) => {
   try {
      await api.post("Measurements/Add", { data: { trdr, measurement } });
      await dispatch(getHydrometers());
   } catch (error) {
      await handleError(error, dispatch, getState);
   }
};
