import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as el from "./locales/el";
// import * as en from "./locales/en";

i18next
   .use(I18nextBrowserLanguageDetector)
   .use(initReactI18next)
   .init({
      fallbackLng: "el",
      lng: "el",
      debug: false,
      interpolation: { escapeValue: false },
      react: {
         useSuspense: false
      },
      supportedLngs: ["en", "el"],
      resources: { en: el, el }
   });

export default i18next;
