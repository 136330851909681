import api from "api";
import {Api, UserInfoModel} from "models";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {ReduxState} from "store";
import {updateHydrometer} from "store/actions";
import {useForm} from "react-hook-form";
import {validations} from "utilities";
import {useTranslation} from "react-i18next";

interface UpdateHydrometerProps {
    cccydrmtr: string;
    trdr: string;
    edit?: string;
    meter: string;
}

const UpdateHydrometer: React.FC<UpdateHydrometerProps> = ({cccydrmtr, trdr, meter, edit}) => {
    const [t] = useTranslation("hydrometer");
    const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
    const [areas, setAreas] = useState<Api.DB.Area[]>([]);
    const [loading, setLoading] = useState(false);
    const submitBtn = useRef<HTMLInputElement>(null);
    const [termsModal, setTermsModal] = useState(false);
    const [reminderModal, setReminderModal] = useState(false);
    const [error, setError] = useState<any>(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const {register, handleSubmit, errors, reset, watch} = useForm<Api.Hydrometer.Update & {
        consent: boolean
    }>({mode: "all"});

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setAreas((await api.get<Api.DB.Area[]>("/DB/Areas")).data);
                if (edit && edit == "true") {
                    const hydrometerInfo = (await api.post<Partial<Api.Hydrometer.Info>>("/Hydrometer/Info", {data: trdr})).data;
                    const defaultValues = {
                        ...hydrometerInfo,
                        eBill: hydrometerInfo.eBill ? "1" : "0",
                        drainage: hydrometerInfo.drainage ? "1" : "0"
                    };
                    reset({...defaultValues, billEmail: userInfo?.email});
                } else reset({eBill: "0", billEmail: userInfo?.email});
            } catch (error) {
                setError(error.response?.data);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleData = async (values: any) => {
        try {
            setLoading(true);
            await dispatch(updateHydrometer({...values, trdr, cccydrmtr}));
            setLoading(false);
            history.push("/UpdateHydrometers?msg=" + encodeURI(t("update_success")));
        } catch (error) {
            setError(error.response?.data);
            setLoading(false);
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(handleData)} disabled={loading}>
                {!edit && (
                    <Row>
                        <Col>
                            <h3 style={{textAlign: "center"}}>{t("hydrometer:statement_title")}</h3>
                            <ol>
                                <li>{t("hydrometer:statement_1")}</li>
                                <li>{t("hydrometer:statement_2")}</li>
                                <li>{t("hydrometer:statement_3")}</li>
                                <li>{t("hydrometer:statement_4")}</li>
                                <li>{t("hydrometer:statement_5")}</li>
                            </ol>
                        </Col>
                    </Row>
                )}
                <hr/>
                <Row className="my-3">
                    <Col>
                        <h5 className="text-center">
                            <span className="font-weight-light">{t("hydrometer:hydrometer")}</span>
                            <strong>{meter}</strong>
                        </h5>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label>{t("hydrometer:relation")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    name="relation"
                                    invalid={!!errors.relation}
                                    type="select"
                                    innerRef={register({required: true, validate: validations.onlyDigits})}
                                >
                                    {/*<option hidden></option>*/}
                                    <option value={1}>{t("hydrometer:owner_option")}</option>
                                    {/*<option value={3}>{t("hydrometer:resident_option")}</option>*/}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label>{t("hydrometer:drainage_connected")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    type="select"
                                    innerRef={register({required: true, validate: validations.onlyDigits})}
                                    name="drainage"
                                    invalid={!!errors.drainage}
                                >
                                    <option hidden></option>
                                    <option value={1}>{t("hydrometer:yes")}</option>
                                    <option value={0}>{t("hydrometer:no")}</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {watch("relation") == 3 && (
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="ownerAfm">{t("hydrometer:owner_afm")}</Label>
                                <InputGroup>
                                    <Input
                                        disabled={loading}
                                        id="ownerAfm"
                                        name="ownerAfm"
                                        invalid={!!errors.ownerAfm}
                                        innerRef={register({required: true, validate: validations.onlyAlphanumeric})}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="ownerName">{t("hydrometer:owner_name")}</Label>
                                <InputGroup>
                                    <Input
                                        disabled={loading}
                                        id="ownerName"
                                        name="ownerName"
                                        invalid={!!errors.ownerName}
                                        innerRef={register({required: true, validate: validations.onlyANSH})}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label for="area">{t("hydrometer:community")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading || !areas || areas.length <= 0}
                                    type="select"
                                    id="area"
                                    name="area"
                                    invalid={!!errors.area}
                                    innerRef={register({required: true, validate: validations.onlyDigits})}
                                >
                                    <option value="" hidden></option>
                                    {areas
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                        .map(({label, id: value}) => (
                                            <option value={value} key={value}>
                                                {label}
                                            </option>
                                        ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label>{t("hydrometer:address")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    name="address.street"
                                    invalid={!!errors.address?.street}
                                    placeholder={t("hydrometer:address_street")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                                <Input
                                    disabled={loading}
                                    name="address.number"
                                    invalid={!!errors.address?.number}
                                    placeholder={t("hydrometer:address_number")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                            </InputGroup>
                            <FormText>{t("no_number")}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>{t("hydrometer:bill_address")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    name="billAddress.region"
                                    invalid={!!errors.billAddress?.region}
                                    placeholder={t("hydrometer:bill_address_region")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                                <Input
                                    disabled={loading}
                                    name="billAddress.postalCode"
                                    invalid={!!errors.billAddress?.postalCode}
                                    placeholder={t("hydrometer:bill_address_postalCode")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                                <Input
                                    disabled={loading}
                                    name="billAddress.street"
                                    invalid={!!errors.billAddress?.street}
                                    placeholder={t("hydrometer:bill_address_street")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                                <Input
                                    disabled={loading}
                                    name="billAddress.number"
                                    invalid={!!errors.billAddress?.number}
                                    placeholder={t("hydrometer:bill_address_number")}
                                    innerRef={register({required: true, validate: validations.onlyANSH})}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label>{t("hydrometer:friendly_name")}</Label>
                            <InputGroup>
                                <Input disabled={loading} name="identifier" invalid={!!errors.identifier}
                                       innerRef={register({required: false, validate: validations.onlyANSH})}/>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label>{t("hydrometer:eBill")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    type="select"
                                    innerRef={register({required: true, validate: validations.onlyDigits})}
                                    name="eBill"
                                    invalid={!!errors.eBill}
                                >
                                    <option value={0}>{t("hydrometer:no")}</option>
                                    <option value={1}>{t("hydrometer:yes")}</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    {watch("eBill") == "1" && (
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label>{t("hydrometer:bill_email")}</Label>
                                <InputGroup>
                                    <Input disabled={loading} readOnly invalid={!!errors.billEmail} name="billEmail"
                                           defaultValue={userInfo?.email} innerRef={register}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <Row className="mt-3">
                    <Col>
                        <h6>{t("hydrometer:form_reminder")}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup check>
                            <InputGroup>
                                <Label check>
                                    <Input
                                        disabled={loading}
                                        type="checkbox"
                                        name="consent"
                                        innerRef={register({required: {value: true, message: "errors:consent"}})}
                                        invalid={!!errors.consent}
                                    />
                                    {t("hydrometer:i_have_read")}
                                    <span
                                        className={"btn-link text-decoration-none"}
                                        style={{cursor: "pointer"}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setTermsModal(true);
                                        }}
                                    >
                              {t("hydrometer:terms")}
                           </span>
                                    {errors.consent?.message &&
                                        <FormFeedback>{t(errors.consent.message)}</FormFeedback>}
                                </Label>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            disabled={loading}
                            className="float-right"
                            type={watch("relation") == 1 && (!edit || edit != "true") ? "button" : "submit"}
                            color="primary"
                            onClick={() => {
                                if (watch("relation") == 1 && (!edit || edit != "true")) setReminderModal(true);
                            }}
                        >
                            {t("hydrometer:submit")}
                        </Button>
                        <input hidden type="submit" ref={submitBtn}/>
                    </Col>
                </Row>
                {error && (
                    <Row>
                        <Col>
                            <p className="text-danger">{t(error.detail || "hydrometer:error")}</p>
                        </Col>
                    </Row>
                )}
                <Modal isOpen={reminderModal} toggle={() => setReminderModal(false)}>
                    <ModalHeader className="font-weight-bold">{t("hydrometer:owner_confirmation_title")}</ModalHeader>
                    <ModalBody>{t("hydrometer:owner_confirmation")}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="button" onClick={() => submitBtn.current?.click()}>
                            {t("hydrometer:continue")}
                        </Button>
                        <Button type="button" onClick={() => setReminderModal(false)}>
                            {t("hydrometer:cancel")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
            <Modal size="lg" isOpen={termsModal} toggle={() => setTermsModal(false)}>
                <ModalHeader>{t("hydrometer:terms_title")}</ModalHeader>
                <ModalBody>
                    <ol>
                        <li>{t("hydrometer:terms_1")}</li>
                        <li>{t("hydrometer:terms_2")}</li>
                        <li>{t("hydrometer:terms_3")}</li>
                        <li>{t("hydrometer:terms_4")}</li>
                        <li>{t("hydrometer:terms_5")}</li>
                        <li>{t("hydrometer:terms_6")}</li>
                        <li>{t("hydrometer:terms_7")}</li>
                    </ol>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UpdateHydrometer;
