import { ConfirmUserInfoModel, UpdateUserInfoModel, UserInfoModel } from "models";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, update, confirm } from "store/actions";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, FormText, Input, InputGroup, Label, Row } from "reactstrap";
import { ReduxState } from "store";
import api from "api";
import { useHistory } from "react-router";
import countries from "i18n-iso-countries";
import telephoneData from "country-telephone-data";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { validations } from "utilities";

const FirstStep: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
   const [t] = useTranslation(["user", "errors"]);
   const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
   const [error, setError] = useState<any>(null);
   const dispatch = useDispatch();
   const form = useForm<UpdateUserInfoModel>({
      mode: "all",
      defaultValues: {
         phoneNumberDialCode: "30",
         phoneNumber: "",
         email: "",
         privacyPolicy: false
      }
   });

   useEffect(() => {
      if (!userInfo) dispatch(getUserInfo());
      else {
         const { email, phoneNumber, phoneNumberDialCode } = userInfo;
         form.reset({ email, phoneNumber, phoneNumberDialCode });
      }
   }, [userInfo]);

   const dialCodeOptions = useMemo(
      () =>
         telephoneData.allCountries
            .map(({ dialCode, iso2 }) => ({ country: countries.getName(iso2, "el"), dialCode }))
            .sort((a, b) => a.country.localeCompare(b.country, "el")),
      []
   );

   const message = (
      <Alert isOpen={true} color="primary">
         <h3>{t("user:welcome")}</h3>
         <p>{t("user:fill_contact_info")}</p>
         <p className="font-italic">{t("user:next_step")}</p>
      </Alert>
   );

   const handleSubmit = useCallback(
      async (data: UpdateUserInfoModel) => {
         try {
            await dispatch(update(data));
            onSubmit();
         } catch (error) {
            if (error.response?.data?.errors) {
               Object.keys(error.response.data.errors).forEach((ek) => {
                  const ekl = (ek[0].toLowerCase() + ek.slice(1)) as keyof UpdateUserInfoModel;
                  if (error.response.data.errors[ek]) form.setError(ekl, { type: "validate", message: error.response.data.errors[ek] });
               });
            } else setError(error.response?.data?.detail || "user:error");
         }
      },
      [onSubmit]
   );

   return (
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
         <Row>
            <Col>{!userInfo?.email && !userInfo?.emailConfirmed && !userInfo?.phoneNumber && !userInfo?.phoneNumberConfirmed && message}</Col>
         </Row>
         <Row>
            <Col xs={12}>
               <FormGroup>
                  <Label>{t("user:email")}</Label>
                  <InputGroup className="mb-2">
                     <Input
                        type="email"
                        disabled={form.formState.isSubmitting}
                        name="email"
                        innerRef={form.register({
                           required: { value: true, message: "errors:required" },
                           pattern: { value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: "errors:invalid_email" }
                        })}
                        invalid={!!form.errors.email}
                        valid={!form.formState.dirtyFields.email && userInfo?.emailConfirmed}
                     />
                     {form.errors.email?.message && <FormFeedback>{t(form.errors.email.message)}</FormFeedback>}
                  </InputGroup>
                  {!form.formState.dirtyFields.email && userInfo?.email && !userInfo.emailConfirmed && <FormText>{t("user:pending_confirmation")}</FormText>}
               </FormGroup>
            </Col>
            <Col xs={12}>
               <FormGroup>
                  <Label>{t("user:phone")}</Label>
                  <InputGroup className="mb-2">
                     <Input
                        type="select"
                        disabled={form.formState.isSubmitting}
                        name="phoneNumberDialCode"
                        innerRef={form.register({
                           required: { value: true, message: "errors:required" }
                        })}
                        invalid={!!form.errors.phoneNumberDialCode}
                     >
                        {dialCodeOptions.map(({ dialCode, country }, i) => (
                           <option key={`dialCode_${i}`} value={dialCode}>
                              {country} (+{dialCode})
                           </option>
                        ))}
                     </Input>
                     <Input
                        type="tel"
                        disabled={form.formState.isSubmitting}
                        name="phoneNumber"
                        innerRef={form.register({
                           required: { value: true, message: "errors:required" },
                           validate: validations.onlyDigits
                        })}
                        invalid={!!form.errors.phoneNumber}
                        valid={!form.formState.dirtyFields.phoneNumber && !form.formState.dirtyFields.phoneNumberDialCode && userInfo?.phoneNumberConfirmed}
                     />
                     {(form.errors.phoneNumber?.message || form.errors.phoneNumberDialCode?.message) && (
                        <FormFeedback className="d-flex">
                           <div className="text-left">{t(form.errors.phoneNumberDialCode?.message || "")}</div>
                           <div className="text-right flex-grow-1">{t(form.errors.phoneNumber?.message || "")}</div>
                        </FormFeedback>
                     )}
                  </InputGroup>
                  {!form.formState.dirtyFields.phoneNumber && !form.formState.dirtyFields.phoneNumberDialCode && userInfo?.phoneNumber && !userInfo.phoneNumberConfirmed && (
                     <FormText>{t("user:pending_confirmation")}</FormText>
                  )}
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>
               <FormGroup check>
                  <InputGroup className="mb-2">
                     <Label check>
                        <Input
                           type="checkbox"
                           name="privacyPolicy"
                           innerRef={form.register({ required: { value: true, message: "errors:consent" } })}
                           invalid={!!form.errors.privacyPolicy}
                           disabled={form.formState.isSubmitting}
                        />
                        {t("user:i_have_read")}
                        <a href="https://deyar.gr/privacy-notice/" target="_blank" rel="noopener noreferrer">
                           {t("user:terms")}
                        </a>
                        {form.errors.privacyPolicy?.message && <FormFeedback>{t(form.errors.privacyPolicy.message)}</FormFeedback>}
                     </Label>
                  </InputGroup>
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>{error && <p className="text-danger width-100 text-right">{t(error)}</p>}</Col>
         </Row>
         <Row>
            <Col>
               <Button className="float-right" type="submit" color="primary" disabled={form.formState.isSubmitting}>
                  {t("user:next")}
               </Button>
            </Col>
         </Row>
      </Form>
   );
};

const SecondStep: React.FC<{ onBack: () => void; onSubmit: () => void }> = ({ onBack, onSubmit }) => {
   const [t] = useTranslation(["user", "errors"]);
   const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
   const [error, setError] = useState<any>(null);
   const dispatch = useDispatch();
   const form = useForm<ConfirmUserInfoModel>({ mode: "all" });

   const handleSubmit = useCallback(
      async (data: ConfirmUserInfoModel) => {
         try {
            await dispatch(confirm(data));
            onSubmit();
         } catch (error) {
            if (error.response?.data?.errors) {
               Object.keys(error.response.data.errors).forEach((ek) => {
                  const ekl = (ek[0].toLowerCase() + ek.slice(1)) as keyof ConfirmUserInfoModel;
                  if (error.response.data.errors[ek]) form.setError(ekl, { type: "validate", message: error.response.data.errors[ek] });
               });
            } else setError(error.response?.data?.detail || "user:error");
         }
      },
      [onSubmit]
   );

   const handleResend = useCallback(async (field: keyof ConfirmUserInfoModel) => {
      try {
         await api.get(`/User/SendConfimationToken/${field[0].toUpperCase() + field.slice(1)}`);
         form.clearErrors(field);
      } catch (err) {
         if (err.response?.data?.detail) form.setError(field, { type: "validate", message: err.response?.data?.detail });
      }
   }, []);

   return (
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
         <Row>
            {!userInfo?.emailConfirmed && (
               <Col xs={12}>
                  <FormGroup>
                     <Label>{t("user:email_confirmation_code")}</Label>
                     <InputGroup className="mb-2">
                        <Input
                           name="email"
                           invalid={!!form.errors.email}
                           disabled={form.formState.isSubmitting}
                           innerRef={form.register({ required: { value: true, message: "errors:required" }, validate: validations.onlyDigits })}
                        />
                        {form.errors.email?.message && <FormFeedback>{t(form.errors.email?.message)}</FormFeedback>}
                     </InputGroup>
                     <FormText>
                        {t("user:msg_error")}
                        <a
                           href="#"
                           className="text-decoration-none"
                           onClick={(e) => {
                              e.preventDefault();
                              handleResend("email");
                           }}
                        >
                           {t("user:here")}
                        </a>
                        {t("user:receive")}
                     </FormText>
                  </FormGroup>
               </Col>
            )}
            {!userInfo?.phoneNumberConfirmed && (
               <Col xs={12}>
                  <FormGroup>
                     <Label>{t("user:phone_confirmation_code")}</Label>
                     <InputGroup className="mb-2">
                        <Input
                           name="phoneNumber"
                           invalid={!!form.errors.phoneNumber}
                           disabled={form.formState.isSubmitting}
                           innerRef={form.register({ required: { value: true, message: "errors:required" }, validate: validations.onlyAlphanumeric })}
                        />
                        {form.errors.phoneNumber?.message && <FormFeedback>{t(form.errors.phoneNumber.message)}</FormFeedback>}
                     </InputGroup>
                     <FormText>
                        {t("user:msg_error")}
                        <a
                           href="#"
                           className="text-decoration-none"
                           onClick={(e) => {
                              e.preventDefault();
                              handleResend("phoneNumber");
                           }}
                        >
                           {t("user:here")}
                        </a>
                        {t("user:receive")}
                     </FormText>
                  </FormGroup>
               </Col>
            )}
         </Row>
         <Row>
            <Col>{error && <p className="text-danger width-100 text-right">{t(error)}</p>}</Col>
         </Row>
         <Row>
            <Col className="d-flex justify-content-end">
               <Button className="mr-2" onClick={onBack} disabled={form.formState.isSubmitting}>
                  {t("user:back")}
               </Button>
               <Button type="submit" color="primary" disabled={form.formState.isSubmitting}>
                  {t("user:submit")}
               </Button>
            </Col>
         </Row>
      </Form>
   );
};

const ContactInfo: React.FC<{ returnUrl: string }> = ({ returnUrl }) => {
   const [step, setStep] = useState(1);
   const history = useHistory();

   return step === 1 ? (
      <FirstStep onSubmit={() => setStep(2)} />
   ) : step === 2 ? (
      <SecondStep onBack={() => setStep(1)} onSubmit={() => history.push(returnUrl || "/")} />
   ) : null;
};

export default ContactInfo;
