import React from "react";
import {Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import {Api} from "models";
import {getGRCurrency} from "utilities";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import qs from "qs";

interface HydrometerProps extends Api.Hydrometer.Info {
    debt: number;
}

const Hydrometer: React.FC<HydrometerProps> = ({debt, ...props}) => {
    const {t} = useTranslation("hydrometer");
    const history = useHistory();
    const addArrangementProps = {
        identifier: props.identifier || `${props.address.street || ""} ${props.address.number || ""}`,
        debt,
        meter: props.meter,
        trdr: props.trdr,
        cccydrmtr: props.cccydrmtr,
    }
    const queryString = qs.stringify(addArrangementProps, {encode: true});

    return (
        <Card className={"mb-4 shadow-sm border-0" + (debt <= 0 ? " bg-light text-white" : "")}>
            <CardBody style={{color: debt > 0 ? undefined : "#9f9f9f"}}>
                <CardTitle className="lead">
                    {t("hydrometer")} <strong>{props.meter}</strong>
                </CardTitle>
                <CardSubtitle
                    className="text-muted">{props.identifier || `${props.address.street || ""} ${props.address.number || ""}`}</CardSubtitle>
                <hr/>
                <Row className="d-flex justify-content-between">
                    <Col xs={12} md={6}>
                        <dl>
                            <dt>{t("arrangements:debt")}</dt>
                            <dd>{getGRCurrency(debt)}</dd>
                        </dl>
                    </Col>
                    <Col className="d-flex justify-content-end" xs={12} sm={8} lg={4}>
                        <div>
                            {debt > 0 && (
                                <Button color="primary" onClick={() => history.push(`/Arrangements/Add?${queryString}`)}>
                                    {t("arrangements:arrangement")}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default Hydrometer;
