import React from "react";
import {Api} from "../../models";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {Col, Row, Table} from "reactstrap";
import {getGRCurrency, getGRDate} from "../../utilities";
import {useTranslation} from "react-i18next";
import {Co} from "react-flags-select";

interface CalculationProps {
    value: Api.Arrangements.Calculation | undefined;
    debt: number;
    loading: boolean;
}

const Calculation: React.FC<CalculationProps> = ({value, debt, loading}) => {
    const [t] = useTranslation("arrangements");

    const loadingView =
        (<SkeletonTheme color="#fff" highlightColor="#f0f2f5">
            <Skeleton height={500}/>
        </SkeletonTheme>);

    const installmentsTable = (<Table bordered striped responsive>
        <thead>
        <tr>
            <th>
                #
            </th>
            <th>
                {t("arrangements:expiry")}
            </th>
            <th>
                {t("arrangements:amount")}
            </th>
        </tr>
        </thead>
        <tbody>
        {value?.installments && value?.installments.length > 0 ? value?.installments.map(({no, expiry, amount}) => (
            <tr>
                <th scope="row">
                    {no}
                </th>
                <td>
                    {getGRDate(expiry)}
                </td>
                <td>
                    {getGRCurrency(amount)}
                </td>
            </tr>
        )) : <tr>
            <th scope="row">
                -
            </th>
            <td>
                -
            </td>
            <td>
                -
            </td>
        </tr>}
        </tbody>
    </Table>);

    const calculationView = (
            <>
                <Row>
                    <Col>
                        <dt>{t("arrangements:initial_debt")}</dt>
                        <dd>{getGRCurrency(value?.initialDebt ?? debt)}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:initial_interest")}</dt>
                        <dd>{value?.initialInterest ? getGRCurrency(value.initialInterest) : "-"}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:interest_discount")}</dt>
                        <dd>{value?.interestDiscount ? getGRCurrency(value.interestDiscount) : "-"}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:final_debt")}</dt>
                        <dd>{value?.finalDebt ? getGRCurrency(value.finalDebt) : "-"}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:min_installment_amount")}</dt>
                        <dd>{value?.minInstallmentAmount ? getGRCurrency(value.minInstallmentAmount) : "-"}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:installments_count")}</dt>
                        <dd>{value?.installments?.length || "-"}</dd>
                    </Col>
                    <Col>
                        <dt>{t("arrangements:installment_amount")}</dt>
                        <dd>{value?.installmentAmount ? getGRCurrency(value?.installmentAmount) : "-"}</dd>
                    </Col>
                </Row>
                {value && 
                    <Row className="mt-5">
                        {
                            value.isRejected ?
                                <>
                                    <Col className="mb-2 text-danger">
                                        <h4>{t("arrangements:arrangement_not_allowed")}</h4>
                                    </Col>
                                    <Col xs={12} className="text-danger">{value.rejectionReason}</Col>
                                </>
                                : 
                                <>
                                    <Col className="mb-2">
                                        <h4>{t("arrangements:installments")}</h4>
                                    </Col>
                                    <Col xs={12}>
                                        {installmentsTable}
                                    </Col>
                                </>
                        }
                    </Row>
                }
            </>
        )
    ;

    return loading ? loadingView : calculationView;
}

export default Calculation;