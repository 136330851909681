import React, {FC, useEffect, useState} from "react";
import {Api} from "models";
import {Alert, Col, Input, Row} from "reactstrap";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import withError from "hoc/withError/withError";
import {useTranslation} from "react-i18next";
import api from "../../api";
import Hydrometer from "../../components/Arrangements/Hydrometer";
import {useHistory} from "react-router";

const Arrangements: FC = () => {
    const [t] = useTranslation(["hydrometer", "messages", "errors"]);
    const [hydrometers, setHydrometers] = useState<Api.Arrangements.Hydrometer[]>();
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (!hydrometers) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await api.get<Api.Arrangements.Hydrometer[]>("/Arrangements/GetHydrometers");
                    setHydrometers(response.data);
                    setErrorMsg("");
                } catch (error) {
                    setErrorMsg(error.response.data.detail || error || "hydrometer:error");
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, []);

    const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

    const loadingView = (
        <SkeletonTheme color="#fff" highlightColor="#f0f2f5">
            <Skeleton height={299}/>
        </SkeletonTheme>
    );

    const defaultView = (
        <Row>
            {hydrometers && hydrometers.length > 0 ? (
                <>
                    <Col xs={12} className="mb-2">
                        <Input type="search" onChange={(e) => setFilter(e.target.value)}
                               placeholder={t("hydrometer:search_hydrometer")}/>
                    </Col>
                    {hydrometers
                        .filter(
                            (h) =>
                                !filter ||
                                new RegExp(filter, "gi").test(
                                    h.info.address.street +
                                    "|" +
                                    h.info.address.street +
                                    "|" +
                                    h.info.address.postalCode +
                                    "|" +
                                    h.info.contract +
                                    "|" +
                                    h.info.eCode +
                                    "|" +
                                    h.info.billEmail +
                                    "|" +
                                    h.info.billAddress.street +
                                    "|" +
                                    h.info.billAddress.region +
                                    "|" +
                                    h.info.billAddress.postalCode +
                                    "|" +
                                    h.info.identifier +
                                    "|" +
                                    h.info.meter +
                                    "|" +
                                    h.info.ownerAfm +
                                    "|" +
                                    h.info.ownerName
                                )
                        )
                        .map((h, i) => (
                            <Col md={6} xs={12} key={"hydro_" + i}>
                                <Hydrometer {...h.info} debt={h.debt}/>
                            </Col>
                        ))}
                </>
            ) : (
                <Col>
                    <h5 className="text-muted d-flex justify-content-center mt-4">{t("messages:no_hydrometers")}</h5>
                    <p className="text-muted d-flex justify-content-center mt-2">{t("messages:update_3")}</p>
                </Col>
            )}
        </Row>
    );

    return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(Arrangements);
