import api from "api";
import { Api } from "models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { validations } from "utilities";

export const AddHydrometer = () => {
   const [t] = useTranslation("hydrometer");
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState<any>(null);
   const { register, handleSubmit, formState } = useForm<Api.Hydrometer.Validation>({ mode: "all" });

   const handleData = async (values: Api.Hydrometer.Validation) => {
      try {
         setLoading(true);
         const { trdr, cccydrmtr } = (await api.post<{ trdr: string; cccydrmtr: string }>("/Hydrometer/IsAvailable", { data: values })).data;
         setLoading(false);
         history.push(`/MyHydrometers/Update?trdr=${trdr}&cccydrmtr=${cccydrmtr}&meter=${values.meter}`);
      } catch (error) {
         setError(error.response?.data);
         setLoading(false);
      }
   };

   const { isDirty, isValid } = formState;
   return (
      <Form onSubmit={handleSubmit(handleData)} disabled={loading}>
         <Row>
            <Col xs={12} md={6}>
               <FormGroup>
                  <Label>{t("hydrometer:meter")}</Label>
                  <InputGroup>
                     <Input disabled={loading} name="meter" innerRef={register({ required: true, validate: validations.onlyANSH })} />
                  </InputGroup>
               </FormGroup>
            </Col>
            <Col xs={12} md={6}>
               <FormGroup>
                  <Label>{t("hydrometer:eCode")}</Label>
                  <InputGroup>
                     <Input disabled={loading} name="eCode" innerRef={register({ required: true, validate: validations.onlyAlphanumeric })} />
                  </InputGroup>
               </FormGroup>
            </Col>
         </Row>
         {error && (
            <Row>
               <Col>
                  <p className="text-danger">{t(error.detail || "hydrometer:error")}</p>
               </Col>
            </Row>
         )}
         <Row>
            <Col>
               <Button disabled={loading || !isDirty || !isValid} className="float-right" type="submit" color="primary">
                  {t("hydrometer:next")}
               </Button>
            </Col>
         </Row>
      </Form>
   );
};

export default AddHydrometer;
