import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "./containers/Layout/Layout";
import { routes, authRoutes, adminRoutes } from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthenticated } from "store/actions";
import { ReduxState } from "store";
import { UserInfoModel } from "models";
import qs from "qs";
import { useTranslation } from "react-i18next";

interface AppState {
   loading: boolean;
   error?: string;
   isAuthenticated: boolean;
}

const App: React.FC = () => {
   const [t] = useTranslation("nav");
   const [loading, setLoading] = useState(true);
   const isAuthenticated = useSelector<ReduxState>((state) => state.isAuthenticated);
   const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
   const confirmedContactInfo = userInfo && userInfo.emailConfirmed && userInfo.phoneNumberConfirmed;
   const dispatch = useDispatch();
   useEffect(() => {
      let params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (params.refresh) {
         delete params.refresh;
         window.location.replace(window.location.href.split("?")[0] + qs.stringify(params, { addQueryPrefix: true }));
      }
      (async () => {
         try {
            setLoading(true);
            await dispatch(checkAuthenticated());
         } catch (error) {
         } finally {
            setLoading(false);
         }
      })();
   }, []);

   return !loading ? (
      <Layout>
         <Switch>
            {routes.map((r, i) => (
               <Route key={"route_" + i} {...r} />
            ))}
            {authRoutes.map((r, i) => {
               if (r.auth !== isAuthenticated)
                  return (
                     <Route
                        key={"auth_route_" + i}
                        {...r}
                        render={() => {
                           window.location.href = `/api/User/ExternalLogin?provider=TaxisNet&returnUrl=${encodeURIComponent(window.location.pathname)}`;
                           return null;
                        }}
                     />
                  );
               if (typeof r.confirmedContactInfo === "undefined" || r.confirmedContactInfo === confirmedContactInfo)
                  return (
                     <Route
                        key={"auth_route_" + i}
                        {...r}
                        render={(routeProps) => (
                           <>
                              {r.name && (
                                 <>
                                    <h5 className="my-3" style={{ width: "100%", textAlign: "center", color: "#194172" }}>
                                       {t(r.name)}
                                    </h5>
                                    <hr />
                                 </>
                              )}
                              {r.render && r.render(routeProps)}
                           </>
                        )}
                     />
                  );
               else
                  return (
                     <Route key={"auth_route_" + i} {...r} render={() => <Redirect to={`/User/ContactInfo?returnUrl=${encodeURIComponent(window.location.pathname)}`} />} />
                  );
            })}
            {adminRoutes
               .filter((r) => r.isAdmin === userInfo?.isAdmin && r.auth === isAuthenticated)
               .map((r, i) => (
                  <Route
                     key={"admin_route_" + i}
                     {...r}
                     render={(routeProps) => (
                        <>
                           {r.name && (
                              <>
                                 <h5 className="my-3" style={{ width: "100%", textAlign: "center", color: "#194172" }}>
                                    {t(r.name)}
                                 </h5>
                                 <hr />
                              </>
                           )}
                           {r.render && r.render(routeProps)}
                        </>
                     )}
                  />
               ))}
            <Route key="route_default" render={() => <Redirect to="/" />} />
         </Switch>
      </Layout>
   ) : null;
};

export default App;
