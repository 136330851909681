import React, {FC, useEffect, useState} from "react";
import {Api} from "models";
import {Alert, Button, Col, Row, Table} from "reactstrap";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import withError from "hoc/withError/withError";
import {useTranslation} from "react-i18next";
import api from "../../api";
import {getGRDate} from "../../utilities";
import {useHistory} from "react-router";

const Applications: FC = () => {
    const [t] = useTranslation(["applications", "errors"]);
    const [applications, setApplications] = useState<Api.Applications.Application[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setApplications((await api.get("/Protocol/Applications/Get")).data);
                setErrorMsg("");
            } catch (error) {
                setErrorMsg(error.response.data.detail || error || "hydrometer:error");
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

    const loadingView = (
        <SkeletonTheme color="#fff" highlightColor="#f0f2f5">
            <Skeleton height={299}/>
        </SkeletonTheme>
    );

    const defaultView = (
        <Row>
            <Col xs={12} className="d-flex justify-content-end">
                <Button color="success" onClick={() => history.push("/Applications/Add")}>
                    {t("applications:add_application")}
                </Button>
            </Col>
            {applications && applications.length > 0 ? (
                <Col xs={12} className="mt-3">
                    <Table bordered>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("applications:application_type")}</th>
                            <th>{t("applications:created")}</th>
                            <th>{t("applications:status")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {applications.map(application =>
                            <tr>
                                <td>{application.id}</td>
                                <td>{application.typeName}</td>
                                <td>{getGRDate((application as any).created)}</td>
                                <td>{application.status}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Col>
            ) : (
                <Col>
                    <h5 className="text-muted d-flex justify-content-center mt-4">{t("applications:no_applications")}</h5>
                </Col>
            )}
        </Row>
    );

    return loading ? loadingView : errorMsg ? errView : defaultView;
};

export default withError(Applications);
