import React from "react";
import {Api} from "../../models";
import {FormGroup, FormText, Input, Label} from "reactstrap";
import {useTranslation} from "react-i18next";

interface DecisionSelectorProps {
    onChange: (decisionId: string | undefined) => void;
    value: string | undefined;
    decisions: Api.Arrangements.Decision[];
    loading: boolean;
}

const DecisionSelector: React.FC<DecisionSelectorProps> = ({onChange, value, decisions, loading}) => {
    const [t] = useTranslation(["arrangements"]);

    return (
        <FormGroup>
            <Label for="decision_select">
                {t("arrangements:decision_select")}
            </Label>
            <Input type="select" id="decision_select" name="decision" value={value} readOnly={loading} disabled={loading}
                   onChange={(ev) => onChange(ev.target.value || undefined)}>
                <option value={undefined}></option>
                {decisions?.map(({id, name}) => <option key={`decision-${id}`} value={id}>{name}</option>)}
            </Input>
        </FormGroup>
    );
}

export default DecisionSelector;