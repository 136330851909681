import React from "react";
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { Link as RLink, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReduxState } from "store";
import { Api, UserInfoModel } from "models";
import { ReactComponent as Receipt } from "assets/material/receipt.svg";
import { ReactComponent as Application } from "assets/material/vote.svg";
import { ReactComponent as Gauge } from "assets/material/gauge.svg";
import { ReactComponent as Handshake } from "assets/material/handshake.svg";
import { ReactComponent as CreditCard } from "assets/material/credit_card.svg";
import { ReactComponent as Edit } from "assets/material/edit.svg";
import { ReactComponent as WaterDrop } from "assets/material/water.svg";
import { ReactComponent as AdminPanel } from "assets/material/admin.svg";
import { useTranslation } from "react-i18next";

const Home: React.FC = () => {
   const [t] = useTranslation(["nav", "messages"]);
   const isAuthenticated = useSelector<ReduxState, boolean>((state) => state.isAuthenticated);
   const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
   const hydrometers = useSelector<ReduxState, Api.Hydrometer.Info[] | null>((state) => state.hydrometers);
   const history = useHistory();

   const confirmedContactInfo = userInfo?.emailConfirmed && userInfo.phoneNumberConfirmed;

   const hydrometresNeedUpdate = hydrometers ? hydrometers.filter((h) => !h.isUpdated).length : -1;
   const hydrometersCount = hydrometers ? hydrometers.length : -1;
   const updateHydrometersWarning =
      isAuthenticated && confirmedContactInfo && (hydrometersCount === 0 || hydrometresNeedUpdate > 0) ? (
         <div className="text-danger">
            <h4 className="text-danger">{t("messages:attention")}!</h4>
            <p style={{ color: "#194172" }}>
               {t("messages:update")}
               {hydrometresNeedUpdate > 0 && (
                  <>
                     <br />
                     <strong>{hydrometresNeedUpdate}</strong> {hydrometresNeedUpdate > 1 ? t("messages:hydrometers") : t("messages:hydrometer")} {t("messages:to_update")}
                  </>
               )}{" "}
               {t("messages:press")} <RLink to="/UpdateHydrometers">{t("messages:here")}</RLink> {t("messages:to_continue")}
            </p>
         </div>
      ) : null;

   const fillContactInfo =
      isAuthenticated && (!userInfo?.emailConfirmed || !userInfo.phoneNumberConfirmed) ? (
         <Alert color="primary" isOpen={true}>
            <h3>{t("messages:fill_contact_info")}</h3>
            <p>
               {t("messages:follow_procedure")} <RLink to="/User/ContactInfo">{t("messages:here")}</RLink> {t("messages:to_gain_access")}
            </p>
         </Alert>
      ) : null;

   return (
      <Container>
         {fillContactInfo}
         {updateHydrometersWarning}
         <Row>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/QuickPayment")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <CreditCard height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:quick_payment")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/UpdateHydrometers")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <Edit height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:update")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/MyBills")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <Receipt height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:my_bills")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/Arrangements")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <Handshake height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:arrangements")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/MyHydrometers")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <WaterDrop height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:my_hydrometers")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/Measurements")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <Gauge height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:measurements")}</h3>
                  </CardBody>
               </Card>
            </Col>
            <Col xs={12} md={6} className="mb-4 service-button">
               <Card className="border-0 shadow" onClick={() => history.push("/Applications")} style={{ height: "100%" }}>
                  <CardBody className="d-flex justify-content-start flex-column align-items-center">
                     <Application height="80px" width="100%" fill="currentColor" />
                     <h3 className="text-center">{t("nav:applications")}</h3>
                  </CardBody>
               </Card>
            </Col>
            {userInfo?.isAdmin && (
               <Col xs={12} md={6} className="mb-4 admin-button">
                  <Card className="border-0 shadow" onClick={() => history.push("/Admin")} style={{ height: "100%" }}>
                     <CardBody className="d-flex justify-content-start flex-column align-items-center">
                        <AdminPanel height="80px" width="100%" fill="currentColor" />
                        <h3 className="text-center">{t("nav:admin")}</h3>
                     </CardBody>
                  </Card>
               </Col>
            )}
         </Row>
      </Container>
   );
};

export default Home;
