import api from "api";
import {Api, UserInfoModel} from "models";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter, Progress,
    Row,
    Spinner
} from "reactstrap";
import {ReduxState} from "store";
import {useForm} from "react-hook-form";
import {validations} from "utilities";
import {useTranslation} from "react-i18next";
import ReactMarkdown from "react-markdown";
import {v4 as uuidv4} from "uuid";
import {Co, Ro} from "react-flags-select";

const NewTabLink: React.FC = ({children, ...props}) =>
    <a target="_blank" {...props}>
        {children}
    </a>;

const MarkDown: React.FC<{ children: string; }> = ({children}) =>
    <ReactMarkdown components={{a: NewTabLink}}>
        {children}
    </ReactMarkdown>;

const AddApplication: React.FC = () => {
    const [t] = useTranslation(["applications", "errors"]);
    const userInfo = useSelector<ReduxState, UserInfoModel | null>((state) => state.userInfo);
    const [applicationTypes, setApplicationTypes] = useState<Api.Applications.ApplicationType[]>([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const submitBtn = useRef<HTMLInputElement>(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [error, setError] = useState<any>(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        errors,
        watch,
        formState,
        trigger
    } = useForm<Api.Applications.Apply & {
        consent: boolean
    }>({mode: "all"});

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                setApplicationTypes((await api.get<Api.Applications.ApplicationType[]>("/protocol/Applications/GetTypes")).data);
            } catch (error) {
                setError(error.response?.data);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleData = async (data: any) => {
        try {
            setLoading(true);

            const transactionId = uuidv4();

            const cleanData = {...data, transactionId, files: undefined};

            let fileIndex = 1;

            for (const fileName in data.files) {
                if (data.files[fileName].length == 0) continue;
                const file: File = data.files[fileName][0];
                const fileModel = await getFileModel(transactionId, fileName, fileIndex, file);
                await api.post(`/Protocol/Applications/UploadFile`, fileModel);
                setProgress(100 * fileIndex / Object.keys(data.files).length);
                fileIndex++;
            }

            const res = await api.post("/Protocol/Applications/Add", {data: {...cleanData, transactionId}});

            setLoading(false);
            setProgress(100);
            history.push("/Applications");
        } catch (error) {
            setError(error.response?.data);
            setProgress(0);
            setLoading(false);
        }
    };

    const getFileModel = async (transactionId: string, name: string, fileIndex: number, file: File) => ({
        transactionId,
        index: fileIndex,
        base64: await readAsBase64(file),
        extension: file.name.split('.').slice(-1)[0],
        name
    });

    const readAsBase64 = (file: File) => new Promise<string | null>((res, rej) => {
        const reader = new FileReader();
        reader.onloadend = e => {
            const result = (reader.result as string).split(',').slice(-1)[0];
            res(result);
        };
        reader.onerror = (ev) => {
            rej(reader.error);
        }
        reader.readAsDataURL(file);
    })

    const closeModal = () => {
        setConfirmationModal(false);
        setError("");
    }

    const selectedApplicationType = applicationTypes.find(x => x.id == watch("typeId"));

    return (
        <>
            <Form disabled={loading}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormGroup>
                            <Label>{t("applications:application_type")}</Label>
                            <InputGroup>
                                <Input
                                    disabled={loading}
                                    name="typeId"
                                    invalid={!!errors.typeId}
                                    type="select"
                                    innerRef={register({required: true, validate: validations.onlyDigits})}
                                >
                                    <option hidden></option>
                                    {applicationTypes.map(x => <option value={x.id}>{x.name}</option>)}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {selectedApplicationType?.comments &&
                    <Row className="mt-2 mb-2">
                        <Col xs={12} style={{whiteSpace: "pre-wrap"}}>
                            <MarkDown>
                                {selectedApplicationType.comments}
                            </MarkDown>
                            <hr/>
                        </Col>
                    </Row>
                }
                {selectedApplicationType && <>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="subject">{t("applications:subject")}</Label>
                                <InputGroup>
                                    <Input
                                        disabled={loading}
                                        id="subject"
                                        name="subject"
                                        invalid={!!errors.subject}
                                        type="textarea"
                                        innerRef={register({
                                            required: {value: true, message: "errors:required"},
                                            maxLength: 250
                                        })}
                                    />
                                    {errors.subject?.message &&
                                        <FormFeedback>{t(errors.subject.message)}</FormFeedback>}
                                </InputGroup>
                                <FormText>{t("applications:subject_helper")}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {selectedApplicationType?.files.map((f, index) =>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for={`files.${selectedApplicationType.id}.${index}`}>{f.name}</Label>
                                    <CustomInput
                                        key={`files.${selectedApplicationType.id}.${index}`}
                                        id={`files.${selectedApplicationType.id}.${index}`}
                                        name={`files.${f.name}`}
                                        disabled={loading || !applicationTypes || applicationTypes.length <= 0}
                                        label={t("applications:choose_file")}
                                        type="file"
                                        multiple={false}
                                        accept="image/*, application/pdf"
                                        invalid={errors.files && (errors.files as any)[f.name]}
                                        innerRef={register({
                                            required: {
                                                value: f.required,
                                                message: "applications:required_file"
                                            },
                                            validate: (value) => {
                                                if (value.length > 0) {
                                                    const file: File = value[0];

                                                    if (!file.type.startsWith("image/") && file.type !== "application/pdf")
                                                        return "errors:invalid_file_type";
                                                }
                                            }
                                        }) as any}
                                    >
                                        {errors.files && (errors.files as any)[f.name] &&
                                            <FormFeedback>{t((errors.files as any)[f.name].message)}</FormFeedback>}
                                    </CustomInput>
                                    {f.comments && <FormText><MarkDown>{f.comments}</MarkDown></FormText>}
                                </FormGroup>
                            </Col>)
                        }
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comments">{t("applications:comments")}</Label>
                                <Input
                                    disabled={loading}
                                    id="comments"
                                    name="comments"
                                    invalid={!!errors.comments}
                                    type="textarea"
                                    innerRef={register({required: false, maxLength: 2000})}
                                />
                                <FormText>{t("applications:comments_helper")}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                disabled={loading || !formState.isValid}
                                className="float-right"
                                type="button"
                                color="primary"
                                onClick={() => setConfirmationModal(true)}
                            >
                                {t("applications:submit")}
                            </Button>
                        </Col>
                    </Row>
                </>}
                <Modal centered size="lg" isOpen={confirmationModal} toggle={closeModal}>
                    <ModalBody>
                        {t("applications:are_you_sure")}
                    </ModalBody>
                    <ModalFooter>
                        {
                            loading &&
                            <Row className="flex-grow-1">
                                <Col xs={12} className="m-0">
                                    {t("applications:submitting")}
                                </Col>
                                <Col xs={12} className="m-0">
                                    <Progress className="flex-grow-1" value={progress} striped>
                                        {`${progress.toFixed(2)} %`}
                                    </Progress>
                                </Col>
                            </Row>

                        }
                        {error && <p className="text-danger">{t(error.detail || "hydrometer:error")}</p>}
                        <Button type="button" onClick={closeModal} disabled={loading}>
                            {t("applications:cancel")}
                        </Button>
                        <Button className="ml-3" type="button" disabled={loading}
                                onClick={handleSubmit(handleData)}
                                color="primary">
                            {loading && <Spinner size="sm"/>}
                            {loading && " "}
                            {t("applications:continue")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </>
    );
};

export default AddApplication;
