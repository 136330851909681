import api, { ServerError } from "api";
import Receipt from "components/Pay/Receipt/Receipt";
import PDFModal from "components/Pdf/PDFModal";
import withError from "hoc/withError/withError";
import { Api } from "models";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation } from "react-router";

interface PaymentsProps {
   id: string;
}

const Receipts: FC<PaymentsProps> = ({ id }) => {
   const [t] = useTranslation("receipts");
   const [receipts, setReceipts] = useState<Api.Pay.Receipt[]>([]);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState<Error | null>(null);
   const [pdf, setPdf] = useState<{ url: string; fileName: string }>({ url: "", fileName: "" });
   const location = useLocation<{ error: string }>();

   useEffect(() => {
      setLoading(true);
      api.get<Api.Pay.Receipt[]>(`Pay/Receipts/${id}`)
         .then((confirmRes) => setReceipts(confirmRes.data))
         .catch((e) => {
            if (e.response) {
               setError(new ServerError(e.response.data?.detail));
            } else setError(new Error(e));
         })
         .finally(() => setLoading(false));
   }, []);

   if (error) throw error;

   return loading ? (
      <SkeletonTheme color="transparent" highlightColor="#fff">
         <Skeleton height={200} />
      </SkeletonTheme>
   ) : (
      <>
         {location?.state?.error && <p className="lead text-danger">{location.state.error}</p>}
         {(!receipts || receipts.length == 0) && (
            <p className="lead">
               {t("receipts:no_docs")} '{id}'
            </p>
         )}
         {!!receipts && receipts.length > 0 && <p className="lead">{t("receipts:docs")}</p>}
         <div className="mb-4"></div>
         {receipts.map((p, i) => (
            <Receipt
               {...p}
               key={"payment_" + i}
               onPdf={(url, fileName) => {
                  setPdf({ url, fileName });
               }}
            />
         ))}
         <PDFModal {...pdf} onClose={() => setPdf({ url: "", fileName: "" })} />
      </>
   );
};

export default withError(Receipts);
