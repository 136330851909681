import React, { useState } from "react";
import { Card, CardTitle, Row, Col, CardBody, FormGroup, FormText, InputGroup, Input, Button, FormFeedback, InputGroupAddon, CardSubtitle } from "reactstrap";
import { Api } from "models";
import { getGRDate } from "utilities";
import { useDispatch } from "react-redux";
import { addMeasurement } from "store/actions";
import { useTranslation } from "react-i18next";

interface HydrometerProps extends Api.Hydrometer.Info {}

const Hydrometer: React.FC<HydrometerProps> = (props) => {
   const { t } = useTranslation("hydrometer");
   const [measurement, setMeasurement] = useState(0);
   const [loading, setLoading] = useState(false);
   const [msg, setMsg] = useState("");
   const [error, setError] = useState(false);
   const dispatch = useDispatch();
   return (
      <Card className={"mb-4 shadow-sm border-0" + (!props.measurement.isOpen ? " bg-light text-white" : "")}>
         <CardBody style={{ color: props.measurement.isOpen ? undefined : "#9f9f9f" }}>
            <CardTitle className="lead">
               {t("hydrometer")} <strong>{props.meter}</strong>
            </CardTitle>
            <CardSubtitle className="text-muted">{props.identifier || `${props.address.street || ""} ${props.address.number || ""}`}</CardSubtitle>
            <hr />
            <Row>
               <Col xs={12} md={6}>
                  <dl>
                     <dt>{t("hydrometer:measurement_range")}</dt>
                     {getGRDate(props.measurement.from, "l") ? (
                        <dd>
                           {t("hydrometer:from")} {getGRDate(props.measurement.from, "l")} {t("hydrometer:to")} {getGRDate(props.measurement.to, "l")}
                        </dd>
                     ) : (
                        <dd>{t("hydrometer:undefined")}</dd>
                     )}
                  </dl>
               </Col>
            </Row>

            <Row className="d-flex justify-content-end">
               <Col xs={12} sm={8} lg={4}>
                  {props.measurement.isOpen && (
                     <FormGroup>
                        <InputGroup>
                           <Input
                              disabled={loading}
                              type="number"
                              onChange={(e) => setMeasurement(e.target.valueAsNumber)}
                              value={measurement}
                              invalid={!loading && error}
                              valid={!loading && !error && !!msg}
                           />
                           <InputGroupAddon addonType="append">
                              <Button
                                 color="info"
                                 className="text-white font-weight-bold"
                                 disabled={measurement <= 0 || loading}
                                 onClick={async () => {
                                    try {
                                       setLoading(true);
                                       await dispatch(addMeasurement(props.trdr, measurement));
                                       // setMeasurement(0);
                                       setMsg("hydrometer:successful_measurement");
                                       setError(false);
                                    } catch (error) {
                                       setMsg(error?.response?.data?.detail || "hydrometer:error");
                                       setError(true);
                                    } finally {
                                       setLoading(false);
                                    }
                                 }}
                              >
                                 {t("hydrometer:submit")}
                              </Button>
                           </InputGroupAddon>
                           {!loading && msg && <FormFeedback valid={!error}>{t(msg)}</FormFeedback>}
                        </InputGroup>
                        <FormText>{t("hydrometer:submit_measurement")}</FormText>
                     </FormGroup>
                  )}
               </Col>
            </Row>
         </CardBody>
      </Card>
   );
};

export default Hydrometer;
