import React, { Component } from "react";
import ErrorInfo from "components/Error/ErrorInfo";
import Warning from "Warning";
import { ServerError } from "api";

interface ErrorBoundaryState {
   hasError: boolean;
   error?: ServerError | Error | Warning;
}

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
   state: ErrorBoundaryState = {
      hasError: false
   };

   static getDerivedStateFromError(error: ServerError | Error | Warning) {
      return { hasError: true, error };
   }

   render() {
      if (this.state.hasError) return <ErrorInfo error={this.state.error} show={this.state.error instanceof ServerError} />;
      else return this.props.children;
   }
}

export default ErrorBoundary;
