import { Reducer } from "redux";
import { ReduxState } from "store";
import types from "./types";

const initialState = {
   isAuthenticated: false,
   userInfo: null,
   hydrometers: null
};

const reducer: Reducer<ReduxState> = (state = initialState, action) => {
   switch (action.type) {
      case types.LOGIN:
         return {
            ...state,
            isAuthenticated: true
         };
      case types.LOGOUT:
         return initialState;
      case types.SET_USER_INFO:
         return {
            ...state,
            userInfo: action.payload
         };
      case types.SET_HYDROMETERS:
         return {
            ...state,
            hydrometers: action.payload
         };
      default:
         return state;
   }
};

export default reducer;
